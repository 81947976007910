import React, { useEffect } from "react";
import "./index.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Root from "./common/layout/root.view";
import Login from "./common/auth/containers/login";
import Logout from "./common/auth/containers/logout";
import Estates from "./estates/containers/estate";
import Landing from "./landing/landing";
import PrivateRoute from "./common/auth/containers/privateRoute";
import { connect } from "react-redux";
import { State } from "./common/reducers";
import { IUserProfile } from "./Types/UserProfileTypes";
import { getRolesData } from "./common/roleChooser/roleChooser.service";
import { IRoleChooser, Role } from "./common/roleChooser/roleChooser";
import { getOrganisationData } from "./organisations/organisations.service";
import { ICompanyInfo } from "./organisations/organisation";
import { IMunicipalityConfig } from "./common/config/municipalityConfig";
import { getMunicipalConfigData } from "./common/config/config.service";
import { createBrowserHistory } from "history";
import InboxPage from "./Pages/Inbox/InboxPage";
import EstatePage from "./Pages/Estates/EstatePage";
import InvoicePage from "./Pages/Invoice/InvoicePage";
import SweepingPage from "./Pages/Sweeping/SweepingPage";
import PropertyTaxPage from "./Pages/PropertyTax/PropertyTaxPage";
import UserProfilePage from "./Pages/UserProfile/UserProfilePage";
import { BootstrapSpinner } from "./common/spinners/bootstrapSpinner";

const estateNewDesign = process.env.REACT_APP_ESTATE_NEW_DESIGN || "false";

function App(props: {
  dispatch: Function;
  ssn: string;
  roleLoading: boolean;
  roleError?: string;
  roles?: IRoleChooser;
  userprofile?: IUserProfile;
  profileError?: string;
  access_token: string;
  organisations?: ICompanyInfo[];
  organisationsError?: string;
  organisationsLoading: boolean;
  config?: IMunicipalityConfig;
  configError?: string;
  configLoading: boolean;
}) {
  let history = createBrowserHistory();

  useEffect(() => {
    if (!!props.ssn) {
      if (!props.config && !props.configError && !props.configLoading) {
        props.dispatch(getMunicipalConfigData(props.access_token));
      }

      if (!props.roles && !props.roleError && !props.roleLoading) {
        props.dispatch(getRolesData(props.access_token));
      }
      if (
        props.roles &&
        props.roles[Role.ORGREP] &&
        !props.organisations &&
        !props.organisationsLoading &&
        !props.organisationsError
      ) {
        const organisationNumbers = Object.keys(props.roles[Role.ORGREP]);
        props.dispatch(
          getOrganisationData(organisationNumbers, props.access_token)
        );
      }
    }
  }, [props]);

  return !!!props.configLoading ? (
    <Router>
      <Routes>
        <Route element={<Root />}>
          {/* <Route path="/" element={<Landing />} /> */}
          <Route path={"/"} element={<PrivateRoute />}>
            <Route path="/" element={<Landing />} />
          </Route>
          <Route path={"/profil"} element={<PrivateRoute />}>
            <Route path="/profil" element={<UserProfilePage />} />
          </Route>
          <Route path={"/eiendommer/*"} element={<PrivateRoute />}>
            <Route
              path="/eiendommer/*"
              element={
                estateNewDesign && estateNewDesign === "true" ? (
                  <EstatePage />
                ) : (
                  <Estates ssn={props.ssn} />
                )
              }
            />
          </Route>
          <Route path={"/feiing-og-boligtilsyn"} element={<PrivateRoute />}>
            <Route path="/feiing-og-boligtilsyn" element={<SweepingPage />} />
          </Route>
          <Route path={"/eiendomsskatt"} element={<PrivateRoute />}>
            <Route path="/eiendomsskatt" element={<PropertyTaxPage />} />
          </Route>
          <Route path={"/post-fra-kommunen"} element={<PrivateRoute />}>
            <Route path="/post-fra-kommunen" element={<InboxPage />} />
          </Route>
          <Route path={"/faktura"} element={<PrivateRoute />}>
            <Route path="/faktura" element={<InvoicePage />} />
          </Route>
          <Route path={"/login/*"} element={<Login myLocation={history} />} />
          <Route path={"/logout/*"} element={<Logout />} />
        </Route>
      </Routes>
    </Router>
  ) : (
    <BootstrapSpinner />
  );
}

export default connect((state: State) => ({
  auth: state.auth,
  roleLoading: state.role.loading,
  roleError: state.role.error,
  roles: state.role.roles,
  organisations: state.organisation.organisations,
  organisationsLoading: state.organisation.loading,
  organisationsError: state.organisation.error,
  ssn:
    (state.auth.user &&
      state.auth.user.profile &&
      state.auth.user.profile.pid) ||
    "",
  access_token: (state.auth.user && state.auth.user.access_token) || "",
  config: state.config.config,
  configLoading: state.config.loading,
  configError: state.config.error,
}))(App);
