import { hasRole, IRoleChooser, Role } from "../roleChooser/roleChooser";

const getOwnerId = (activeRole: IRoleChooser): string => {
  let ownerId = "";

  const organisations = Object.keys(activeRole[Role.ORGREP] || {}).filter(
    (org: string) => hasRole(Role.ORGREP + "." + org, activeRole)
  );

  if (activeRole[Role.PRIVATE]) {
    var activeRoleSsn = Object.keys(activeRole[Role.PRIVATE])[0];
    if (
      hasRole(
        Role.PRIVATE + "." + activeRoleSsn + "." + Role.ESTATEOWNER,
        activeRole
      )
    ) {
      ownerId = activeRoleSsn;
    }
  } else if (organisations.length === 1) {
    ownerId = organisations[0];
  }

  return ownerId;
};

export { getOwnerId };
