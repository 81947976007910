import {
    FETCH_ROLECHOOSERDATA_BEGIN,
    FETCH_ROLECHOOSERDATA_SUCCESS,
    FETCH_ROLECHOOSERDATA_FAILURE,
    SET_ROLE,
} from './roleChooser.actions';
import { IRoleChooser, isPartialTree } from './roleChooser';

export type RoleChooserState = {
    roles?: IRoleChooser;
    activeRole: IRoleChooser;
    loading: boolean;
    error?: string;
};

export default function roleChooserReducer(
    state: RoleChooserState = {
        activeRole: JSON.parse(sessionStorage.getItem('active_role') || '{}'),
        loading: false,
    },
    action: any
) {
    switch (action.type) {
        case FETCH_ROLECHOOSERDATA_BEGIN:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case FETCH_ROLECHOOSERDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload,
                activeRole: isPartialTree(state.activeRole, action.payload) ? state.activeRole : action.payload
            };
        case FETCH_ROLECHOOSERDATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                roles: {}
            };
        case SET_ROLE:
            sessionStorage.setItem('active_role', JSON.stringify(action.payload));
            return {
              ...state,
              activeRole: action.payload
            };
        default:
            return state;
    }   
}