import { Tab, Nav, Row, Col } from 'react-bootstrap';
import React from 'react';
import { ITabCollection } from '../interfaces/tabCollection';
import { DropdownView } from './dropdown.view';

type DropdownViewState = {
  key: number;
};

type DropdownViewProps = {
  collection: any[];
  defaultIndex?: number;
  onEntered?: Function;
  onExit?: Function;
};
/**
 *  initializes and renders TabHeader and Tabcontent components.
 * @param collection: ITabCollection[]
 * @param defaultIndex: optional number. When set, activeTabIndex will be equal to this.
 * @param onEntryChange: optional function with tab index as input. Triggers on tabHeader's onClick.
 */
export default class DropdownContentView extends React.Component<
  DropdownViewProps,
  DropdownViewState
  > {
  constructor(props: any) {
    super(props);
    this.state = {
      key: this.props.defaultIndex || 0
    };
  }

  createDropdown(dropdownCollection: ITabCollection[]) {
    return (
      <DropdownView
        onClickItem={(index: number) => {
          this.setState({ key: index })
        }}
        dropDownItems={dropdownCollection.map(items => items.header)}
        activeItem={this.state.key !== -1 ? dropdownCollection[this.state.key].header : dropdownCollection[0].header}
      />
    );
  }

  createDropdownContent(dropdownCollection: ITabCollection[]) {
    return dropdownCollection.map((dropdownContentObj: ITabCollection, index: number) => (
      <Tab.Pane 
        eventKey={index} 
        key={index} 
        onEntered={() => {
          this.props.onEntered && this.props.onEntered(index);
        }}
        onExit={() => {
          this.props.onExit && this.props.onExit(index);
        }}
        > 
        {dropdownContentObj.body}
      </Tab.Pane>
    ));
  }

  render() {
    const { collection } = this.props;
    const activeKey = this.state.key;
    return (
      <Tab.Container activeKey={activeKey} onSelect={() => null} mountOnEnter={true}>
        <Row>
          <Col xs={12} md={11} className="ikta-tab-navigation">
            <div className="estate-dropdown mt-auto">
              <Nav variant='tabs'> 
                {this.createDropdown(collection)} 
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="ikta-tab-navigation-content">
          <Col className="col-12">
            <Tab.Content>{this.createDropdownContent(collection)}</Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

