import { ICommonPropertyTaxModel } from "../Types/PropertyTaxTypes";

/**
 * Fetches sweepingdata for the users estates.
 * @param municipalKey string determing municipality.
 * @param token string, access_token from ID porten.
 * @param ownerId string of numbers 11(ssn) or 9(organisation number) digits long.
 */
async function getPropertyTaxData(
  municipalKey: string,
  token: string,
  ownerId: string
): Promise<ICommonPropertyTaxModel[]> {
  let url = `${process.env.REACT_APP_DATA_API}/api/Estate/GetCommonPropertyTaxForEstate/${municipalKey}`;

  if (ownerId.trim().length === 9)
    url = `${process.env.REACT_APP_DATA_API}/api/Estate/GetCommonPropertyTaxForOrganizationEstate/${municipalKey}/${ownerId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

export { getPropertyTaxData };
