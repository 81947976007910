import React from "react";
import { State } from "../common/reducers";
import { connect } from "react-redux";
import { createServiceCardData } from "../common/serviceCard/createServiceCardData";
import { IRoleChooser } from "../common/roleChooser/roleChooser";
import ServiceCardView, {
  IServiceCardData,
} from "../common/serviceCard/card.view";
import { IMunicipalityConfig } from "../common/config/municipalityConfig";
import { BootstrapSpinner } from "../common/spinners/bootstrapSpinner";

export class Landing extends React.Component<
  {
    loading: boolean;
    activeRole: IRoleChooser;
    error?: string;
    ssn: string;
    access_token: string;
    configValues?: IMunicipalityConfig;
  },
  {}
> {
  createServiceCards() {
    const serviceCards = createServiceCardData(
      this.props.activeRole,
      this.props.configValues
    );
    return serviceCards.map((card: IServiceCardData, index: number) => (
      <ServiceCardView key={index} {...card} />
    ));
  }

  render() {
    if (!this.props.loading) {
      return (
        <div className="container landing-container">
          <h1 className="landing-header">Min side</h1>
          {this.createServiceCards()}
        </div>
      );
    } else
      return (
        <div className="container">
          <div className="spinner">
            <BootstrapSpinner title="Laster" />
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state: State) => {
  return {
    activeRole: state.role.activeRole || {},
    loading: state.role.loading,
    error: state.role.error,
    ssn:
      (state.auth.user &&
        state.auth.user.profile &&
        state.auth.user.profile.pid) ||
      "",
    access_token: (state.auth.user && state.auth.user.access_token) || "",
    configValues: state.config.config,
  };
};

export default connect(mapStateToProps)(Landing);
