import React from "react";

type IconProps = {
  fillColor: string;
};

function ArrowLeftIcon(props: IconProps) {
  return (
    <svg
      width="21"
      height="32"
      viewBox="0 0 61.93 61.78"
      fill={props.fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="c"
        d="M18.91,50.94l21.58-21.58h0L18.91,7.78l3.5-3.5,25.08,25.08h0c-8.36,8.36-16.72,16.72-25.08,25.08"
      />
    </svg>
  );
}
export { ArrowLeftIcon };
