import {
    LOGIN_BEGIN,
    LOGIN_SUCCESS, 
    LOGIN_FAILED,
    LOGOUT_FAILED,
    LOGOUT_SUCCESS,
} from './auth.actions';
import { User } from 'oidc-client';

export type AuthState = {
    user?: User;
    error?: Error;
    loading?: boolean;
};

const authReducer = (state: AuthState = {}, action: any) => {
    switch (action.type) {
        case LOGIN_BEGIN:
            return {
                ...state,
                loading: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: undefined,
                loading: false
            };
        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                error: undefined,
                user: undefined,
                loading: false
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
export default authReducer;