import { IServiceCardData } from "./card.view";
import { Role, IRoleChooser } from "../roleChooser/roleChooser";
import { IMunicipalityConfig } from "../config/municipalityConfig";

const hasSweeping = process.env.REACT_APP_SWEEPING_ENABLED || "false";
const hasPropertyTax = process.env.REACT_APP_PROPERTYTAX_ENABLED || "false";
const hasInbox = process.env.REACT_APP_INBOX_ENABLED || "false";
const hasInvoices = process.env.REACT_APP_INVOICES_ENABLED || "false";

/**
 * Generates an array of card data based on the users role.
 * Used for generating UI for available services
 * @param roles
 */

export function createServiceCardData(
  roles: IRoleChooser,
  configValues?: IMunicipalityConfig
): IServiceCardData[] {
  const cards = [];
  let privatePersonRoles: IRoleChooser[] = [];
  let organisationRepRoles: IRoleChooser[] = [];

  if (!!roles[Role.PRIVATE]) {
    privatePersonRoles = Object.values(roles[Role.PRIVATE]);
    if (!!!configValues?.profileService.basicConfig.hideService)
      cards.push({
        path: "/profil",
        header: "Min profil",
        body: configValues
          ? configValues?.profileService.basicConfig.cardText
          : "Kontaktopplysningene vi har registrert om deg.",
      });
    if (
      hasInbox === "true" &&
      !!!configValues?.inboxService.basicConfig.hideService
    )
      cards.push({
        path: "/post-fra-kommunen",
        header: "Post fra kommunen",
        body: configValues
          ? configValues?.inboxService.basicConfig.cardText
          : "Digitale brev sendt til \n deg fra kommunen.",
      });
  }

  if (
    hasInvoices === "true" &&
    !!!configValues?.invoiceService.basicConfig.hideService
  )
    cards.push({
      path: "/faktura",
      header: "Faktura",
      body: configValues
        ? configValues?.invoiceService.basicConfig.cardText
        : "Fakturaer som er sendt til deg fra kommunen.",
    });

  if (!!roles[Role.ORGREP]) {
    organisationRepRoles = Object.values(roles[Role.ORGREP]);
  }

  const combinedValues = [...privatePersonRoles, ...organisationRepRoles];
  if (
    combinedValues.some(
      (subRole) => Object.keys(subRole).toString() === Role.ESTATEOWNER
    )
  ) {
    if (!!!configValues?.estateService.basicConfig.hideService)
      cards.push({
        path: "/eiendommer",
        header: "Eiendommer",
        body: configValues
          ? configValues?.estateService.basicConfig.cardText
          : "Dokumenter og informasjon om \n dine eiendommer.",
      });

    if (
      hasPropertyTax === "true" &&
      !!!configValues?.estateTaxService.basicConfig.hideService
    )
      cards.push({
        path: "/eiendomsskatt",
        header: "Eiendomsskatt",
        body: configValues
          ? configValues?.estateTaxService.basicConfig.cardText
          : "Vedtatt eiendomsskatt \n og beregning.",
      });

    if (
      hasSweeping === "true" &&
      !!!configValues?.sweepingService.basicConfig.hideService
    )
      cards.push({
        path: "/feiing-og-boligtilsyn",
        header: "Feiing og boligtilsyn",
        body: configValues
          ? configValues?.sweepingService.basicConfig.cardText
          : "Feiing og boligtilsyn \n på din eiendom.",
      });
  }
  return cards;
}
