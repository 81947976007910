import {
    FETCH_PROPERTYTAXDATA_BEGIN,
    FETCH_PROPERTYTAXDATA_SUCCESS,
    FETCH_PROPERTYTAXDATA_FAILURE,
    RESET_PROPERTYTAX
} from './propertyTax.actions';
import { IPropertyTax } from './propertyTaxRelation';

export type PropertyTaxState = {
    propertyTax?: IPropertyTax[];
    loading: boolean;
    error?: string;
};

export const defaultTaxState = {
    propertyTax: undefined,
    loading: false
}

export default function propertyTaxReducer(
    state: PropertyTaxState = defaultTaxState,
    action: any
) {
    switch (action.type) {
        case FETCH_PROPERTYTAXDATA_BEGIN:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case FETCH_PROPERTYTAXDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                propertyTax: action.payload
            };
        case FETCH_PROPERTYTAXDATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                propertyTax: undefined
            };
        case RESET_PROPERTYTAX:
            return {
                ...state,
                loading: false,
                error: undefined,
                propertyTax: undefined,
            }
        default:
            return state;
    }
}