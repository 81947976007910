import { IEstate, Owner } from "../estateRelation";
import React from "react";

type EstateCoreInformationProps = {
  estate: IEstate;
  address?: string | undefined;
};
/**
 * @param estate: A single IEstate object.
 * @param address: Optional string with one address. When inserted,the address will
 * be outputted alongside other values-
 */

export function EstateCoreInformation(props: EstateCoreInformationProps) {
  const ownedSince = new Date(props.estate.ownerRelation.ownedSince);

  function getOwnerRelation(ownerRelationCode: string) {
    switch (ownerRelationCode.toUpperCase()) {
      case "H":
        return "(Hjemmelshaver)";
      case "F":
        return "(Fester)";
      default:
        return "";
    }
  }

  return (
    <section className="core-info">
      <div className="row">
        <div className="col-12">
          <table className="table table-borderless ikta-table-1">
            <thead>
              <tr>
                <td>
                  <h3 className="core-info-title">Eiendomsinformasjon</h3>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <TableRow label="Adresse" value={props.address} />
              <TableRow
                label="Kommune"
                value={`${
                  props.estate.address.municipalityName
                    ? props.estate.address.municipalityName
                    : ""
                } ${
                  props.estate.address.municipalityNumber
                    ? props.estate.address.municipalityNumber
                    : ""
                }`}
              />
              <TableRow
                label="Gårdsnummer"
                value={props.estate.ownerRelation.matrikkelenhetIdent.gardsnr}
              />
              <TableRow
                label="Bruksnummer"
                value={props.estate.ownerRelation.matrikkelenhetIdent.bruksnr}
              />
              <TableRow
                label="Festenummer"
                value={props.estate.ownerRelation.matrikkelenhetIdent.festenr}
              />
              <TableRow
                label="Seksjonsnummer"
                value={
                  props.estate.ownerRelation.matrikkelenhetIdent.seksjonsnr
                }
              />
              <tr style={{ display: "none" }}>
                <td className="data-label">Overtakelsesdato</td>
                <td className="data-value">
                  {ownedSince.getDate() +
                    "." +
                    ownedSince.getMonth() +
                    "." +
                    ownedSince.getFullYear()}
                </td>
              </tr>
              {props.estate.ownerRelation.owners &&
                props.estate.ownerRelation.owners.length > 0 && (
                  <tr>
                    <td className="data-label">Eies av</td>
                    <td className="data-value">
                      <ul>
                        {props.estate.ownerRelation.owners
                          .sort((a: Owner, b: Owner) => {
                            if (a.ownerRelationCode < b.ownerRelationCode)
                              return -1;

                            return 1;
                          })
                          .map((owner: Owner, index: number) => (
                            <li key={index} className="owner-name">
                              {owner.name.toLowerCase() +
                                " " +
                                getOwnerRelation(owner.ownerRelationCode)}
                            </li>
                          ))}
                      </ul>
                    </td>
                  </tr>
                )}
              <TableRow
                label="Eiendommens areal"
                value={
                  props.estate.matrikkelenhet.calculatedArea &&
                  `${props.estate.matrikkelenhet.calculatedArea.toFixed(1)} m²`
                }
              />
              {props.estate.matrikkelenhet.aktiveFestegrunner && (
                <tr>
                  <td className="data-label">Aktive festegrunner</td>
                  <td className="data-value">Ja</td>
                </tr>
              )}
              {props.estate.matrikkelenhet.coOwnershipFraction.numerator &&
                props.estate.matrikkelenhet.coOwnershipFraction.denominator && (
                  <tr>
                    <td className="data-label">Eierbrøk</td>
                    <td className="data-value">
                      {
                        props.estate.matrikkelenhet.coOwnershipFraction
                          .numerator
                      }{" "}
                      /{" "}
                      {
                        props.estate.matrikkelenhet.coOwnershipFraction
                          .denominator
                      }
                    </td>
                  </tr>
                )}
              {props.estate.matrikkelenhet.groundPollution && (
                <tr>
                  <td className="data-label">Grunnforurensning</td>
                  <td className="data-value">Ja</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

const TableRow = ({ label, value }: any) =>
  value ? (
    <tr>
      <td className="data-label">{label}</td>
      <td className="data-value">{value}</td>
    </tr>
  ) : null;
