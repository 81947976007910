/**
 * Sorts unitNumbers ascending with the following format: 
 * "{LETTER}{NUMBERS}".
 * E.g: H0101 || U0101 || H1303
 * @param a string unitnumber
 * @param b string unitnumber
 * @returns -1 when a is less than b. 1 when a is greater than b. 
 */
export const sortUnitNumbers = (a: string, b: string) => {
    if (a.length < 1 || b.length < 1) return 0;
  
    const aNumber = parseInt(a.substr(1))
    const bNumber = parseInt(b.substr(1))
    if (isNaN(aNumber) || isNaN(bNumber)) return 0;
  
    return aNumber < bNumber ? -1 : 1
  }
  
  