import React from "react";
import { Dropdown } from "react-bootstrap";

type DropdownViewProps = {
  dropDownItems: React.ReactElement[] | any;
  onClickItem: (index: number) => void | any;
  activeItem: React.ReactElement | any;
};
/**
 * Renders a dropdown menu where one string element is set to active, and
 * displayed in the toggle.
 * @param dropDownItems: array of strings with items to be rendered in dropdown menu.
 * First element in array is set to active.
 * @param onClickItem: function trigger onClick on an Dropdown.item, passes item string value.
 */
export function DropdownView(props: DropdownViewProps) {
  return (
    <Dropdown>
      <Dropdown.Toggle className="dropdown-btn">
        {props.activeItem}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.dropDownItems.map((item: React.ReactElement, index: number) => (
          <Dropdown.Item
            className={item === props.activeItem ? "selected" : ""}
            key={index.toString()}
            onClick={() => {
              props.onClickItem(index);
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
