import React from 'react';
import { AddressNames } from '../estateRelation';
import { sortUnitNumbers } from '../../common/utilities/sortFunction';

type AddressViewProp = {
  addressNames: AddressNames[]
}

export function AddressView(props: AddressViewProp) {
  return (
    <div>
      {props.addressNames.map((addressNames: AddressNames, addressIndex: number) => (
        <div key={addressIndex}>
          <h5>{addressNames.text}</h5>
          <h6>Bruksenheter</h6>
          {addressNames.unitNumbers.sort((a: string, b: string) => sortUnitNumbers(a, b))
            .map((unitNumber: string, unitIndex: number) => (
              <div key={`${addressIndex}-${unitIndex}`}>
                <div className='data-value '>{unitNumber}</div>
              </div>
            ))}
          <br />
        </div>
      ))}
    </div>
  )
}