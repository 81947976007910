import {
    FETCH_ESTATEDATA_BEGIN,
    FETCH_ESTATEDATA_FAILURE,
    FETCH_ESTATEDATA_SUCCESS,
    FETCH_ESTATEARCHIVE_BEGIN,
    FETCH_ESTATEARCHIVE_FAILURE,
    FETCH_ESTATEARCHIVE_SUCCESS,
    FETCH_GRUNNBOKPDF_BEGIN,
    FETCH_GRUNNBOKPDF_FAILURE,
    FETCH_GRUNNBOKPDF_SUCCESS
  } from './estates.actions';
  import { IEstate } from './estateRelation';
import propertyTaxReducer, { defaultTaxState, PropertyTaxState } from './propertyTax/propertyTax.reducer';
  
  export type EstateState = {
    estateSets: { [represents: string]: IEstate[] };
    propertyTax: PropertyTaxState;
    loadingEstates: boolean;
    loadingArchive: boolean;
    loadingGetPdf: boolean;
    loadingGetPdfFailed: boolean;
    error?: string;
    fileResponse?: File;
  };
  
  export default function estateReducer(
    state: EstateState = {
      estateSets: {},
      propertyTax: defaultTaxState,
      loadingEstates: false,
      loadingArchive: false,
      loadingGetPdf: false,
      loadingGetPdfFailed: false,
      fileResponse: undefined
    },
    action: any
  ) {
    const estateSets = {
      ...state.estateSets
    };
    switch (action.type) {
      case FETCH_ESTATEDATA_BEGIN:
        return {
          ...state,
          loadingEstates: true,
          error: undefined
        };
      case FETCH_ESTATEDATA_SUCCESS:
        estateSets[action.ownerId] = action.payload;
        return {
          ...state,
          loadingEstates: false,
          estateSets
        };
      case FETCH_ESTATEDATA_FAILURE:
        estateSets[action.ownerId] = [];
        return {
          ...state,
          loadingEstates: false,
          error: action.payload,
          estateSets
        };
      case FETCH_ESTATEARCHIVE_BEGIN:
        return {
          ...state,
          loadingArchive: true,
          error: undefined
        };
      case FETCH_ESTATEARCHIVE_SUCCESS:
        estateSets[action.ownerId][action.currentEstate].documents = action.payload;
        return {
          ...state,
          loadingArchive: false,
          estateSets
        };
      case FETCH_ESTATEARCHIVE_FAILURE:
        estateSets[action.ownerId][action.currentEstate].documents = [];
        return {
          ...state,
          loadingArchive: false,
          error: action.payload,
          estateSets
        };
      case FETCH_GRUNNBOKPDF_BEGIN:
        return {
          ...state,
          loadingGetPdf: true,
          loadingGetPdfFailed: false,
          error: undefined
        };
      case FETCH_GRUNNBOKPDF_SUCCESS:
        return {
          ...state,
          loadingGetPdf: false,
          loadingGetPdfFailed: false,
          fileResponse: action.payload
        };
      case FETCH_GRUNNBOKPDF_FAILURE:
        return {
          ...state,
          loadingGetPdf: false,
          loadingGetPdfFailed: true,
          error: action.payload

        };
      default:
        return {
          ...state,
          propertyTax: propertyTaxReducer(state.propertyTax, action),
        };
    }
  }
  