import { IEstate, AddressNames } from "../estateRelation";
import { getEstateData, getGrunnbokPdfForEstate } from "../estates.service";
import { State } from "../../common/reducers";
import { connect, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import Tabview from "../../common/tab/tab.view";
import { IAccordionData } from "../../common/interfaces/accordionData";
import { AddressView } from "../views/address.view";
import BuildingView from "../views/building.view";
import { ITabCollection } from "../../common/interfaces/tabCollection";
import { Role, hasRole } from "../../common/roleChooser/roleChooser";
import { EstateCoreInformation } from "../views/estateCoreInformation.view";
import { AccordionView } from "../../common/accordion/accordion.view";
import MapWithTiles from "../../common/map/mapWithTiles.view";
import DropdownContentView from "../../common/dropdown/dropdownContent.view";
import { resetPropertyTax } from "../propertyTax/propertyTax.actions";
import { PropertyTax } from "../propertyTax/propertyTax";
import { useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { LinkOutIcon } from "../../Icons/LinkOutIcon";
import { getOwnerId } from "../../common/utilities/roleHelpers";
import { usePropertyTaxQuery } from "../../Hooks";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

const hasPropertyTaxAsService =
  process.env.REACT_APP_PROPERTYTAX_ENABLED ||
  "/404?REACT_APP_PROPERTYTAX_ENABLED";
const hidePropertyTax =
  process.env.REACT_APP_HIDE_ESTATE_PROPERTYTAX ||
  "/404?REACT_APP_HIDE_ESTATE_PROPERTYTAX";

type EstatesProps = {
  dispatch: Function;
  ssn: string;
  access_token: string;
  loadingEstates: boolean;
  loadingArchive: boolean;
  error?: string;
  represents: string;
  estates?: IEstate[];
  loadingGetPdf: boolean;
  loadingGetPdfFailed: boolean;
};

function Estates(props: EstatesProps) {
  let location = useLocation();
  let history = createBrowserHistory();

  const municipalKey =
    process.env.REACT_APP_MUNICIPALITY_NAME ||
    "/404?REACT_APP_MUNICIPALITY_NAME";

  const activeRole = useSelector((state: State) => state.role.activeRole);
  const ownerId = getOwnerId(activeRole);

  let invalidators: { [x: string]: () => void }[] = [];
  let ref: { [x: string]: any } = {};

  const estateService = useSelector(
    (state: State) => state.config.config?.estateService || undefined
  );
  const { data: propertyTaxItems, isLoading: isFetchingPropertyTaxItems } =
    usePropertyTaxQuery(municipalKey, ownerId);

  /**
   * Iterates estates, and returns the index of element with same municipalityNumber,
   * gardsNr, bruksNr as queryParameters.
   * @param estates Array of IEstate
   * @param urlSearchParams URLSearchParams
   * @returns SelectedIndex. Index of estate matching queryParameters within properties array.
   * If no estate matches queryParameters, retun -1.
   */
  function findEstateByQueryParameters(
    estates: IEstate[],
    urlSearchParams: URLSearchParams
  ): number {
    return estates.findIndex((estate: IEstate) => {
      const matrikkelenhetIdent = estate.ownerRelation.matrikkelenhetIdent;
      let seksjonsnr = matrikkelenhetIdent.seksjonsnr
        ? matrikkelenhetIdent.seksjonsnr
        : 0;
      let festenr = matrikkelenhetIdent.festenr
        ? matrikkelenhetIdent.festenr
        : 0;
      return (
        matrikkelenhetIdent.kommunenr === urlSearchParams.get("kommuneNr") &&
        matrikkelenhetIdent.gardsnr.toString() ===
          urlSearchParams.get("gardsNr") &&
        matrikkelenhetIdent.bruksnr.toString() ===
          urlSearchParams.get("bruksNr") &&
        seksjonsnr.toString() === urlSearchParams.get("seksjonsNr") &&
        festenr.toString() === urlSearchParams.get("festeNr")
      );
    });
  }

  /**
   * Uses data for selected index to create queryParams.
   * Push this new queryParam to history.
   * @param estates Array of IEstate
   * @param activeIndex Index in properties which is active
   */
  function setQueryParametersToActiveIndex(
    estates: IEstate[],
    activeIndex: number
  ) {
    //const estate = estates[activeIndex].ownerRelation.matrikkelenhetIdent;
    // let seksjonsnr = estate.seksjonsnr ? estate.seksjonsnr : 0;
    // let festenr = estate.festenr ? estate.festenr : 0;
    // if (estate.kommunenr && estate.gardsnr && estate.bruksnr) {
    //   //console.log(location);
    //   // history.push(
    //   //   "?kommuneNr=" +
    //   //     estate.kommunenr +
    //   //     "&gardsNr=" +
    //   //     estate.gardsnr +
    //   //     "&bruksNr=" +
    //   //     estate.bruksnr +
    //   //     "&seksjonsNr=" +
    //   //     seksjonsnr +
    //   //     "&festeNr=" +
    //   //     festenr
    //   // );
    // }
  }

  /**
   * @param estate One IEstate object
   */
  function createAccordionData(
    estate: IEstate,
    tabIndex: number
  ): IAccordionData[] {
    let accordionData = [];
    const matrikkelenhetIdent = estate.ownerRelation.matrikkelenhetIdent;
    let seksjonsnr = matrikkelenhetIdent.seksjonsnr
      ? matrikkelenhetIdent.seksjonsnr
      : 0;
    let festenr = matrikkelenhetIdent.festenr ? matrikkelenhetIdent.festenr : 0;
    const showForMunicipalityNumbers = process.env.REACT_APP_MUNICIPALITY_NUMBER
      ? process.env.REACT_APP_MUNICIPALITY_NUMBER.split(" ")
      : "";
    if (
      estate.addresses &&
      estate.addresses.addressNames != null &&
      (estate.addresses.addressNames.length > 1 ||
        estate.addresses.addressNames.filter(
          (adressNames: AddressNames) => adressNames.unitNumbers.length > 1
        ).length > 0)
    ) {
      accordionData.push({
        title: "Adresser",
        content: <AddressView addressNames={estate.addresses.addressNames} />,
      });
    }

    if (estate.buildings && estate.buildings.length > 0) {
      accordionData.push({
        title: "Bygninger",
        content: <BuildingView buildings={estate.buildings} />,
      });
    }

    if (showForMunicipalityNumbers.includes(matrikkelenhetIdent.kommunenr)) {
      accordionData.push({
        title: "Dokumenter (Doctorg)",
        content: (
          <div>
            <p>
              I Doctorg kan du se alle offentlige dokumenter om din eiendom fra
              arkivene byggesak, oppmåling og vann og avløp.
            </p>
            <>
              <a
                className="ikta-button"
                href="https://doctorg.no/autologin"
                target="_blank"
                rel="noopener noreferrer"
              >
                Se dokumenter i Doctorg
                <div className="ikta-linkout">
                  <LinkOutIcon fillColor={"White"} />
                </div>
              </a>
            </>
          </div>
        ),
      });

      if (
        hasPropertyTaxAsService !== "true" &&
        hidePropertyTax !== "true" &&
        propertyTaxItems
      ) {
        accordionData.push({
          title: "Eiendomsskatt",
          content: (
            <PropertyTax
              propertyTax={propertyTaxItems[0]}
              loading={isFetchingPropertyTaxItems}
            />
          ),
        });
      }

      accordionData.push({
        title: "Grunnboksinformasjon",
        content: (
          <>
            <p>
              Grunnboken er et offentlig register, som viser tinglyste
              rettigheter og forpliktelser i eiendom. Grunnboken var tidligere
              en bok med håndskrevne ark, og er i dag et dataregister som
              forvaltes av Kartverket.
            </p>
            <Button
              className="ikta-button"
              disabled={props.loadingGetPdf}
              onClick={() =>
                props.dispatch(
                  getGrunnbokPdfForEstate(
                    props.access_token,
                    matrikkelenhetIdent.kommunenr,
                    matrikkelenhetIdent.gardsnr,
                    matrikkelenhetIdent.bruksnr,
                    seksjonsnr,
                    festenr
                  )
                )
              }
            >
              {props.loadingGetPdf
                ? "Henter grunnbokutskrift"
                : "Last ned grunnbokutskrift"}
              <div className="ikta-linkout">
                <LinkOutIcon fillColor={"White"} />
              </div>
            </Button>
            {props.loadingGetPdfFailed ? (
              <p>Kunne ikke hente grunnboksutskrift</p>
            ) : (
              ""
            )}
          </>
        ),
      });
    }
    return accordionData;
  }

  function createTabCollection(estates: IEstate[]): ITabCollection[] {
    return estates.map((estate: IEstate, tabIndex: number): ITabCollection => {
      ref[tabIndex] = ref[tabIndex] || {};
      invalidators[tabIndex] = invalidators[tabIndex] || {};
      const matrikkelenhetIdent = estate.ownerRelation.matrikkelenhetIdent;
      return {
        header: (
          <div>
            {estate.addresses &&
            estate.addresses.addressList &&
            estate.addresses.addressList.length === 1 &&
            estate.addresses.addressList[0].text.length > 0
              ? estate.addresses.addressList[0].text
              : matrikkelenhetIdent.kommunenr +
                "/" +
                matrikkelenhetIdent.gardsnr +
                "/" +
                matrikkelenhetIdent.bruksnr +
                "/" +
                (matrikkelenhetIdent.festenr !== undefined
                  ? matrikkelenhetIdent.festenr
                  : 0) +
                "/" +
                (matrikkelenhetIdent.seksjonsnr !== undefined
                  ? matrikkelenhetIdent.seksjonsnr
                  : 0)}
            <div className="arrow-down" />
          </div>
        ),
        body: (
          <div>
            <EstateCoreInformation
              estate={estate}
              address={
                estate.addresses &&
                estate.addresses.addressList &&
                estate.addresses.addressList.length === 1
                  ? estate.addresses.addressList[0].text
                  : undefined
              }
            />
            <AccordionView
              content={createAccordionData(estate, tabIndex)}
              reference={ref[tabIndex]}
            />
            {estate.addresses &&
            estate.addresses.addressList &&
            estate.addresses.addressList.length === 1 ? (
              <MapWithTiles
                lat={estate.addresses.addressList[0].coordinates.lat}
                lon={estate.addresses.addressList[0].coordinates.lon}
                invalidate={
                  invalidators
                    ? (i: any) => {
                        invalidators[tabIndex]["parent"] = i;
                      }
                    : undefined
                }
              />
            ) : (
              ""
            )}
          </div>
        ),
      };
    });
  }

  if (!props.loadingEstates && !!props.represents && !props.estates) {
    props.dispatch(getEstateData(props.represents, props.access_token));
  }
  const estates = props.estates || [];

  let activeEstate = -1;
  if (!props.loadingEstates && estates.length > 0) {
    activeEstate = findEstateByQueryParameters(
      estates,
      new URL(window.location.href).searchParams
    );
    if (activeEstate === -1 && location.search !== "") {
      const search = "";
      history.push(search);
      // props.history.replace({
      //   ...location,
      //   search,
      // });
    }
  }

  if (estateService?.basicConfig.hideService)
    return (
      <>
        <div className="container estate-container">
          <Col className="order-xs-2 order-md-1 col-12" xs={12}>
            <h1 className="estate-header">Eiendommer</h1>
            <p>{"Tjenesten er desverre ikke tilgjengelig."}</p>
          </Col>
        </div>
      </>
    );
  else
    return (
      <div className="container estate-container">
        <Col className="order-xs-2 order-md-1 col-12" xs={12}>
          <h1 className="estate-header">Eiendommer</h1>
          {estateService?.basicConfig.alertText &&
            estateService?.basicConfig.alertText !== "" && (
              <div className="infoBoxLanding">
                <p>{estateService?.basicConfig.alertText}</p>
              </div>
            )}
          <p>{estateService?.basicConfig.ingressText}</p>
        </Col>
        <Row>
          <Col xs={{ span: 12 }}>
            <section className="my-properties col-12">
              {!props.loadingEstates ? (
                estates.length > 0 ? (
                  <div>
                    <div
                      className={
                        estates.length > 4
                          ? "hidden"
                          : estates.length > 2
                          ? "hidden-on-mobile"
                          : ""
                      }
                    >
                      <Tabview
                        collection={createTabCollection(estates)}
                        defaultIndex={activeEstate !== -1 ? activeEstate : 0}
                        onEntered={(activeKey: number) => {
                          props.dispatch(resetPropertyTax());
                          setQueryParametersToActiveIndex(estates, activeKey);
                          invalidators[activeKey]["parent"] &&
                            invalidators[activeKey]["parent"]();
                        }}
                        onExit={(index: number) => {
                          ref[index].reset && ref[index].reset();
                        }}
                      />
                    </div>
                    <div
                      className={
                        estates.length <= 2
                          ? "hidden"
                          : estates.length <= 4
                          ? "hidden-on-desktop"
                          : ""
                      }
                    >
                      <DropdownContentView
                        collection={createTabCollection(estates)}
                        defaultIndex={activeEstate !== -1 ? activeEstate : 0}
                        onEntered={(activeKey: number) => {
                          props.dispatch(resetPropertyTax());
                          setQueryParametersToActiveIndex(estates, activeKey);
                          invalidators[activeKey]["parent"] &&
                            invalidators[activeKey]["parent"]();
                        }}
                        onExit={(index: number) => {
                          ref[index].reset && ref[index].reset();
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  estateService?.basicConfig.notFoundText
                )
              ) : (
                <div className="spinner">
                  <BootstrapSpinner title="Henter dine eiendommer" />
                </div>
              )}
            </section>
          </Col>
        </Row>
      </div>
    );
}

const mapStateToProps = (state: State) => {
  const organisations = Object.keys(
    state.role.activeRole[Role.ORGREP] || {}
  ).filter((org: string) =>
    hasRole(Role.ORGREP + "." + org, state.role.activeRole)
  );
  let ownerId = "";

  if (state.role.activeRole[Role.PRIVATE]) {
    var activeRoleSsn = Object.keys(state.role.activeRole[Role.PRIVATE])[0];
    if (
      hasRole(
        Role.PRIVATE + "." + activeRoleSsn + "." + Role.ESTATEOWNER,
        state.role.activeRole
      )
    ) {
      ownerId = activeRoleSsn;
    }
  } else if (organisations.length === 1) {
    ownerId = organisations[0];
  }

  return {
    represents: ownerId,
    estates: state.estate.estateSets[ownerId],
    loadingEstates: state.estate.loadingEstates,
    loadingArchive: state.estate.loadingArchive,
    error: state.estate.error,
    access_token: (state.auth.user && state.auth.user.access_token) || "",
    loadingGetPdf: state.estate.loadingGetPdf,
    loadingGetPdfFailed: state.estate.loadingGetPdfFailed,
  };
};
export default connect(mapStateToProps)(Estates);
