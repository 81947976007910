import { ReactElement, useEffect, useRef } from "react";
import { MapContainer, WMSTileLayer, ZoomControl } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { AccordionView } from "../../common/accordion/accordion.view";
import { IAccordionData } from "../../common/interfaces/accordionData";
import { State } from "../../common/reducers";
import { getOwnerId } from "../../common/utilities/roleHelpers";
import { AddressNames, IEstate } from "../../estates/estateRelation";
import { getEstateData } from "../../estates/estates.service";
import { PropertyTax } from "../../estates/propertyTax/propertyTax";
import EstateAddresses from "./EstateAddresses";
import EstateBuildings from "./EstateBuildings";
import EstateCoreInformation from "./EstateCoreInformation";
import EstateDocuments from "./EstateDocuments";
import EstateGrunnbok from "./EstateGrunnbok";
import { usePropertyTaxQuery } from "../../Hooks";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

const hasPropertyTaxAsService =
  process.env.REACT_APP_PROPERTYTAX_ENABLED ||
  "/404?REACT_APP_PROPERTYTAX_ENABLED";
const hidePropertyTax =
  process.env.REACT_APP_HIDE_ESTATE_PROPERTYTAX ||
  "/404?REACT_APP_HIDE_ESTATE_PROPERTYTAX";

const showForMunicipalityNumbers = process.env.REACT_APP_MUNICIPALITY_NUMBER
  ? process.env.REACT_APP_MUNICIPALITY_NUMBER.split(" ")
  : "";
const municipalKey =
  process.env.REACT_APP_MUNICIPALITY_NAME || "/404?REACT_APP_MUNICIPALITY_NAME";

const EstatePage = () => {
  const dispatch = useDispatch();
  const mapRef = useRef<any>();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const estateService = useSelector(
    (state: State) => state.config.config?.estateService || undefined
  );

  const accessToken = useSelector((state: State) =>
    state.auth.user ? state.auth.user.access_token : ""
  );
  const loadingEstates = useSelector(
    (state: State) => state.estate.loadingEstates
  );

  const activeRole = useSelector((state: State) => state.role.activeRole);
  const ownerId = getOwnerId(activeRole);
  const estates = useSelector(
    (state: State) => state.estate.estateSets[ownerId]
  );
  const { data: propertyTaxItems, isLoading: isFetchingPropertyTaxItems } =
    usePropertyTaxQuery(municipalKey, ownerId);

  useEffect(() => {
    if (estates === undefined)
      dispatch<any>(getEstateData(ownerId, accessToken));
  }, [dispatch, accessToken, estates, ownerId]);

  const createSubAccordionData = (data: IEstate) => {
    let subAccordions: {
      title: string;
      content: ReactElement;
      onClick?: Function;
    }[] = [];

    if (
      data.addresses &&
      data.addresses.addressNames != null &&
      (data.addresses.addressNames.length > 1 ||
        data.addresses.addressNames.filter(
          (adressNames: AddressNames) => adressNames.unitNumbers.length > 1
        ).length > 0)
    ) {
      subAccordions.push({
        title: "Adresser",
        content: <EstateAddresses addressNames={data.addresses.addressNames} />,
      });
    }

    if (data.buildings && data.buildings.length > 0) {
      subAccordions.push({
        title: "Bygninger",
        content: <EstateBuildings buildings={data.buildings} />,
      });
    }

    if (
      showForMunicipalityNumbers.includes(
        data.ownerRelation.matrikkelenhetIdent.kommunenr
      ) &&
      municipalKey !== "vennesla"
    ) {
      subAccordions.push({
        title: "Dokumenter (Doctorg)",
        content: <EstateDocuments />,
      });
    }

    if (
      hasPropertyTaxAsService !== "true" &&
      hidePropertyTax !== "true" &&
      propertyTaxItems
    ) {
      subAccordions.push({
        title: "Eiendomsskatt",
        content: (
          <PropertyTax
            propertyTax={propertyTaxItems[0]}
            loading={isFetchingPropertyTaxItems}
          />
        ),
      });
    }

    subAccordions.push({
      title: "Grunnboksinformasjon",
      content: (
        <EstateGrunnbok
          matrikkelenhetIdent={data.ownerRelation.matrikkelenhetIdent}
        />
      ),
    });

    return subAccordions;
  };

  const createAccordionData = (): IAccordionData[] => {
    let accordionData: { title: string; content: JSX.Element }[] = [];

    if (estates && estates.length > 0) {
      estates.map((data: IEstate) => {
        const matrikkelenhetIdent = data.ownerRelation.matrikkelenhetIdent;
        return accordionData.push({
          title: data.address
            ? data.address.text
            : matrikkelenhetIdent.kommunenr +
              "/" +
              matrikkelenhetIdent.gardsnr +
              "/" +
              matrikkelenhetIdent.bruksnr +
              "/" +
              (matrikkelenhetIdent.festenr !== undefined
                ? matrikkelenhetIdent.festenr
                : 0) +
              "/" +
              (matrikkelenhetIdent.seksjonsnr !== undefined
                ? matrikkelenhetIdent.seksjonsnr
                : 0),
          content: (
            <>
              <EstateCoreInformation
                estate={data}
                address={
                  data.address && data.address.text.length > 0
                    ? data.address.text
                    : undefined
                }
              />
              <AccordionView
                showNarrow={true}
                hideBorder={true}
                content={createSubAccordionData(data)}
              />
              {data.addresses &&
              data.addresses.addressList &&
              data.addresses.addressList.length === 1 ? (
                <MapContainer
                  ref={mapRef}
                  style={{
                    height: "500px",
                  }}
                  center={[
                    data.address.coordinates.lat,
                    data.address.coordinates.lon,
                  ]}
                  dragging={!!!isTabletOrMobile}
                  tap={!!!isTabletOrMobile}
                  zoom={19}
                  scrollWheelZoom={false}
                  zoomControl={false}
                >
                  <ZoomControl position={"topleft"} />
                  <WMSTileLayer
                    layers="webatlas-gray-vektor"
                    url={`https://www.webatlas.no/maptiles/wms?apitoken=${"ec0b3777-596e-4dce-b309-3e00d4483b77"}`}
                    maxZoom={25}
                    minZoom={14}
                    format="image/png"
                    attribution="&copy; 2023 Norkart AS/Geovekst og kommunene/OpenStreetMap/NASA, Meti, Norkart AS, Norge Digitalt"
                  />
                  <WMSTileLayer
                    layers="Eiendomskart_Bygningsnr"
                    url={`https://waapi.webatlas.no/wms-matrikkelkart/?apitoken=${"ec0b3777-596e-4dce-b309-3e00d4483b77"}`}
                    maxZoom={25}
                    minZoom={14}
                    format="image/png"
                    transparent={true}
                    attribution=""
                  />
                </MapContainer>
              ) : (
                ""
              )}
            </>
          ),
        });
      });
    }

    return accordionData;
  };

  if (loadingEstates)
    return <BootstrapSpinner title="Henter eiendommene dine" />;
  else
    return (
      <>
        <div className="container my-properties">
          <h1>Eiendommer</h1>
          {estateService?.basicConfig.hideService ? (
            "Tjenesten er dessverre ikke tilgjengelig."
          ) : (
            <>
              {estateService?.basicConfig.alertText &&
                estateService?.basicConfig.alertText !== "" && (
                  <div className="infoBoxLanding">
                    <p>{estateService?.basicConfig.alertText}</p>
                  </div>
                )}
              <p>{estateService?.basicConfig.ingressText}</p>
              <AccordionView
                content={createAccordionData()}
                mapref={mapRef}
                hideBorder={true}
              />
            </>
          )}
        </div>
      </>
    );
};

export default EstatePage;
