import { ISearchResult } from "../Types/MessageTypes";

/**
 * Fetches estate data for a person.
 * @param municipalKey string determing municipality.
 * @param token oidc access token.
 */
async function getInboxData(
  municipalKey: string,
  token: string
): Promise<ISearchResult> {
  const url = `${process.env.REACT_APP_DATA_API}/api/message/${municipalKey}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

export { getInboxData };
