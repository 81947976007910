import { IRoleChooser } from "./roleChooser";

export const FETCH_ROLECHOOSERDATA_BEGIN = 'FETCH_ROLECHOOSERDATA_BEGIN';
export const FETCH_ROLECHOOSERDATA_SUCCESS = 'FETCH_ROLECHOOSERDATA_SUCCESS';
export const FETCH_ROLECHOOSERDATA_FAILURE = 'FETCH_ROLECHOOSERDATA_FAILURE';
export const SET_ROLE = 'SET_ROLE';

export const fetchRoleChooserDataBegin = () => ({
    type: FETCH_ROLECHOOSERDATA_BEGIN
});

export const fetchRoleChooserDataSuccess = (roles: IRoleChooser) => ({
    type: FETCH_ROLECHOOSERDATA_SUCCESS,
    payload: roles,
})

export const fetchRoleChooserDataFailure = (error: Error) => ({
    type: FETCH_ROLECHOOSERDATA_FAILURE,
    payload: error
})

export const setRole = (role: IRoleChooser) => ({
    type: SET_ROLE,
    payload: role
})
