import { Spinner } from "react-bootstrap";
import Variables from "../../customStyles/_export.scss";

export const BootstrapSpinner = (props: {
  title?: string;
  inline?: boolean;
}) => {
  return (
    <div>
      <p style={{ textAlign: "center" }}>
        <Spinner style={{ color: Variables.mainColor }} animation="border" />
        {!props.inline && <br />}
        {props.title || "Laster..."}
      </p>
    </div>
  );
};
