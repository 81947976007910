import './map.css';
import React from 'react';
import * as L from 'leaflet';
import {webatlasTileLayer, WebatlasTileLayerTypes} from 'leaflet-webatlastile';
import { GestureHandling } from "leaflet-gesture-handling";
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

const maxZoom = 24;
const minZoom = 7;
const startZoom = 19;

export default class MapWithTiles extends React.Component {
    map = null;
    map_id = 'map_' + Math.trunc(Math.random() * 10000);
    mapRef = React.createRef();

    componentDidMount() {
        const apiKey = 'ec0b3777-596e-4dce-b309-3e00d4483b77';
        if (!this.map) {
            L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
            const maxWidth = window.innerWidth;
            const mapWidth = this.mapRef.current ? this.mapRef.current.offsetWidth : 0;
            const diffWidth = maxWidth - mapWidth;
            
            this.map = L.map(this.map_id, {
                gestureHandling: diffWidth <= 150,
                gestureHandlingOptions: {
                    duration: 3000,
                    text: {
                        touch: "Bruk to fingre for å flytte på kartet",
                        scroll: "Bruk ctrl + scroll for zoom",
                        scrollMac: "Bruk \u2318 + scroll for zoom"
                    }
                },
                maxZoom,
                minZoom,
            }).setView([this.props.lat, this.props.lon], startZoom);
        }
        webatlasTileLayer({apiKey: apiKey, mapType: WebatlasTileLayerTypes.GREY}).addTo(this.map);

        L.tileLayer.wms(`https://waapi.webatlas.no/wms-matrikkelkart/?apitoken=${apiKey}`, {
            layers: 'Eiendomskart_Bygningsnr',
            maxZoom: 25,
            minZoom: 14,
            format: 'image/png',
            transparent: true,
            attribution: "Norkart AS, Norge Digitalt"
        })
        .addTo(this.map);

        if (this.props.invalidate) {
            this.props.invalidate(() => this.map.invalidateSize());
        }
    }

    render() {
        return (<div className='map-with-tiles'>
            <div id={this.map_id} ref={this.mapRef} className={'tinymap'} onMouseEnter={() => {
                if (!!this.map) {
                    this.map.invalidateSize();
                }
            }} />
        </div>);
    }
}