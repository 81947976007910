import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { ICommonPropertyTaxDetailModel } from "../../Types/PropertyTaxTypes";

const municipalKey =
  process.env.REACT_APP_MUNICIPALITY_NAME || "/404?REACT_APP_MUNICIPALITY_NAME";

type PropertyTaxViewProp = {
  propertyTaxDetails: ICommonPropertyTaxDetailModel;
  showHeader: boolean;
};

export function PropertyTaxView(props: PropertyTaxViewProp) {
  const estatePropertyTaxUrl = useSelector(
    (state: State) =>
      state.config.config?.estateTaxService.propertyTaxUrl || undefined
  );

  if (!!!props.propertyTaxDetails)
    return (
      <>Fant ingen eiendomsskatt registrert på dette gårds- og bruksnummeret.</>
    );

  return (
    <div className="container p-0">
      <div id="propertyTaxContainer">
        {props.showHeader ? (
          <>
            <div className="row">
              <div className="rate-container">
                <h3>
                  Vedtatt eiendomsskatt ={" "}
                  {props.propertyTaxDetails.calculatedTaxTotal} kr pr. år.
                </h3>
              </div>
            </div>
            <h4 className="mt-5">Slik beregnes taksten</h4>
          </>
        ) : (
          <>
            <h2>Beregning</h2>
            {municipalKey !== "froland" && <h3>Slik beregnes taksten</h3>}
          </>
        )}
        {municipalKey !== "froland" && (
          <>
            <p>
              Taksten er resultatet av en vurdering av din eiendom. Taksten skal
              samsvare med markedsverdien på eiendommen din.
              <br />
              Vi bruker informasjon om eiendommen fra matrikkelen (Norges
              offisielle eiendomsregister, med informasjon om eiendomsgrenser,
              areal, bygninger, boliger og adresser) sammen med egne vurderinger
              når vi beregner taksten.
              <br />
              <br />
              Taksten regnes ut denne måten:
            </p>
            <div className="taxFormula">
              Sjablongtakst * Sonefaktor * Indre vurderingsfaktor * Ytre
              vurderingsfaktor = Takst
            </div>
            <ul>
              <li>
                Sjablontakst: Denne regnes ut fra bestemte forhold som
                kvadratmeter og areal. Taksten er uavhengig av f.eks.
                beliggenhet og tilstand.
              </li>
              <li>
                Sonefaktor: Denne er fastsatt ut fra det generelle prisnivået i
                et område. Den tar ikke hensyn til spesielle forhold for
                eiendommen.
              </li>
              <li>
                Indre vurderingsfaktor: Dette er forhold på eiendommen som f.eks
                alder, tilstand, opparbeiding av eiendommen, brygger og
                svømmebasseng.
              </li>
              <li>
                Ytre vurderingsfaktor: Dette er forhold rundt eiendommen som
                f.eks. støy fra vei, utsikt og nærhet til sjøen.
              </li>
            </ul>
            <Col lg={{ span: 5 }} xs={{ span: 12 }} className="p-0">
              <table className="tax-calculation">
                <tbody>
                  <tr className="calculation-row">
                    <th>Sjablongtakst:</th>
                    <td>
                      {
                        props.propertyTaxDetails.templateTaxTotal.split(
                          "kr "
                        )[1]
                      }{" "}
                      kr
                    </td>
                  </tr>
                  <tr className="calculation-row">
                    <th>* Sonefaktor:</th>
                    <td>{props.propertyTaxDetails.zoneFactor}</td>
                  </tr>
                  <tr className="calculation-row">
                    <th>* Indre vurderingsfaktor:</th>
                    <td>{props.propertyTaxDetails.innerFactor}</td>
                  </tr>
                  <tr className="calculation-row">
                    <th>* Ytre vurderingsfaktor:</th>
                    <td>{props.propertyTaxDetails.outerFactor}</td>
                  </tr>
                  <tr className="sum">
                    <th className="font-weight-bold">= Takst:</th>
                    <td className="font-weight-bold">
                      {props.propertyTaxDetails.valuationTotal.split("kr ")[1]}{" "}
                      kr
                    </td>
                  </tr>
                  <tr className="calculation-row">
                    <td colSpan={2}>
                      <div className="double-line"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <br />
          </>
        )}
        <h3>Slik beregnes eiendomsskatten</h3>
        <p>
          Fra 2020 sier lov om eiendomsskatt at taksten på bolig- og
          fritidseiendommer skal reduseres med 30 %. Det er ingen obligatorisk
          reduksjon på andre typer eiendommer. Det er{" "}
          {municipalKey === "froland" ? "kommunestyret" : "bystyret"} som vedtar
          hvilken reduksjon, skattesats (promille) og ev. bunnfradrag vi skal ha
          i vår kommune.
        </p>
        <Col lg={{ span: 5 }} xs={{ span: 12 }} className="p-0">
          <table className="tax-calculation">
            <tbody>
              <tr className="calculation-row">
                <th>Takst:</th>
                <td>{props.propertyTaxDetails.valuationTotal} kr</td>
              </tr>
              <tr className="calculation-row">
                <th>* Skattenivå:</th>
                <td>{props.propertyTaxDetails.taxRate} %</td>
              </tr>
              <tr className="calculation-row">
                <th>- Bunnfradrag:</th>
                <td>{props.propertyTaxDetails.deductionTotal} kr</td>
              </tr>
              <tr className="sum">
                <th>= Skattegrunnlag:</th>
                <td>{props.propertyTaxDetails.taxBasisTotal} kr</td>
              </tr>
              <tr className="calculation-row">
                <th>* Skattesats:</th>
                <td>{props.propertyTaxDetails.thousandRate} ‰</td>
              </tr>
              <tr className="sum">
                <th className="font-weight-bold">= Eiendomsskatt pr. år:</th>
                <td className="font-weight-bold">
                  {props.propertyTaxDetails.calculatedTaxTotal} kr
                </td>
              </tr>
              <tr className="calculation-row">
                <td colSpan={2}>
                  <div className="double-line"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        {estatePropertyTaxUrl && (
          <>
            <br />
            <h3>Har du spørsmål?</h3>
            <p>
              På vår{" "}
              <a href={estatePropertyTaxUrl}>nettside om eiendomsskatt</a> kan
              du lese mer om eiendomsskatt. Her finner du også kontaktinfo og
              skjema for klage på takst.
            </p>
          </>
        )}
      </div>
    </div>
  );
}
