export enum Role {
    PRIVATE = 'Privatperson',
    ORGREP = 'Organisasjonsrepresentant',
    ESTATEOWNER = 'Eier av eiendom'
  }
  
  export interface IRoleChooser {
    [role: string]: IRoleChooser;
  }
  
  /**
   * Checks if a role object has a given role
   * @param rolePath Dot-separated list of roles. \
   * for role object {'a':{'c': true},'b': true}, rolePath could be 'a', 'a.c' or 'b'
   * @param roleObject The role object to assert. With role object {'a':true}, the following returns true: hasRole('a', {'a':true})
   */
  export const hasRole = (rolePath: string, roleObject: IRoleChooser): boolean | IRoleChooser => {
    const firstPath = rolePath.split('.')[0]
    if (firstPath === rolePath) {
      return roleObject[rolePath];
    }
    if (!roleObject[firstPath]) {
      return false;
    }
    if (roleObject[firstPath]) {
      return hasRole(rolePath.slice(firstPath.length + 1), roleObject[firstPath]);
    }
    return false;
  }
  
  /**
   * Checks if an object is a partial of another object. \
   * Emty object is not a partial of anything.
   * @param a partial tree
   * @param b parent tree
   */
  export const isPartialTree = (a: any, b: any): boolean => {
    if (typeof a !== 'object' || typeof b !== 'object') {
      return a === b;
    }
    return Object.keys(a).length > 0 && Object.keys(a).every((key) => {
      return b.hasOwnProperty(key) && isPartialTree(a[key], b[key]);
    });
  }