import { Card, Accordion, Button } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

type AccordionCardContentViewProps = {
  onClick: any;
  onEntered: any;
  title: string;
  eventKey: string;
  content: React.ReactElement;
  isActive: boolean;
  showNarrow?: boolean;
};
/**
 *
 * @param onClick: function for handling onClick functionality. Typically sets active state in parent component.
 * @param title: title placed in Card.Header - always visible
 * @param eventKey: Distinctive string key amongst other AccordionCardContentView in same parent instance.
 * @param content: Content placed in Card.Body - only visible when AccordionCardContentView is selected.
 * @param isActive: Whether is selected or not.
 */
export default function AccordionCardContentView(
  props: AccordionCardContentViewProps
) {
  return (
    <div>
      <Card
        className={
          props.isActive
            ? "accordion-content-active"
            : "accordion-content-inactive"
        }
      >
        <Card.Header
          as={Button}
          onClick={props.onClick}
          className={
            (props.eventKey === "0" ? "first" : "") +
            " rounded-0" +
            (props.showNarrow === true
              ? " showSubAccordion card-headerSub"
              : " card-headerMain")
          }
        >
          <FontAwesomeIcon
            className={
              props.showNarrow === true
                ? " plus-minus-iconSub"
                : " plus-minus-iconMain"
            }
            icon={props.isActive ? faMinus : faPlus}
          />
          {props.title}
        </Card.Header>
        <Accordion.Collapse
          className={props.isActive ? "show" : ""}
          eventKey={props.eventKey}
          onEntered={props.onEntered}
        >
          <Card.Body>{props.content}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
}
