import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, completeLogout } from "../auth.actions";
import { Navigate, useLocation } from "react-router-dom";
import { State } from "../../reducers";

function Logout() {
  const error = useSelector((state: State) => state.auth.error);
  let location = useLocation();
  let dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/logout/response") {
      dispatch<any>(completeLogout());
    } else {
      dispatch<any>(logout());
    }
  }, [location, dispatch]);

  if (error) {
    return <>{error.message}</>;
  } else {
    return <Navigate to={{ pathname: "/" }} />;
  }
}

export default Logout;
