import React from "react";
import "./inbox.scss";
import { useInboxQuery } from "../../Hooks";
import { parseDateToString } from "../../common/utilities/parser";
import Variables from "../../customStyles/_export.scss";
import { DownloadIcon } from "../../Icons/DownloadIcon";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

const InboxPage = () => {
  const municipalKey =
    process.env.REACT_APP_MUNICIPALITY_NAME ||
    "/404?REACT_APP_MUNICIPALITY_NAME";

  const inboxService = useSelector(
    (state: State) => state.config.config?.inboxService || undefined
  );

  const { data: inboxItems, isPending: isFetchingInboxItems } =
    useInboxQuery(municipalKey);

  return (
    <>
      <div className="container inbox-container">
        <h1>Post fra kommunen</h1>
        {inboxService?.basicConfig.hideService ? (
          "Tjenesten er dessverre ikke tilgjenglig."
        ) : (
          <>
            {inboxService?.basicConfig.alertText &&
              inboxService?.basicConfig.alertText !== "" && (
                <div className="infoBoxLanding">
                  <p>{inboxService?.basicConfig.alertText}</p>
                </div>
              )}
            <p>{inboxService?.basicConfig.ingressText}</p>

            {isFetchingInboxItems ? (
              <BootstrapSpinner />
            ) : (
              <>
                {!!!inboxItems || inboxItems.treff.length === 0 ? (
                  <div className="no-inbox-item">
                    {inboxService?.basicConfig.notFoundText}
                  </div>
                ) : (
                  inboxItems?.treff.map((item, index) => {
                    return (
                      <div className="inbox-item" key={index}>
                        <div>
                          <h2>{item.meldingMetadata.tittel}</h2>

                          <p>
                            Fra {item.meldingMetadata.avsender},{" "}
                            {parseDateToString(
                              new Date(item.meldingMetadata.sendtDato)
                            )}
                          </p>

                          <div>
                            {item.meldingMetadata.dokumenter.map(
                              (document, docIndex) => {
                                return (
                                  <div key={docIndex}>
                                    <a
                                      href={document.nedlastingLenke}
                                      title={`Last ned ${document.tittel}`}
                                    >
                                      {document.tittel +
                                        " " +
                                        `(${document.size / 1000} kB)`}
                                    </a>{" "}
                                    <DownloadIcon
                                      fillColor={Variables.mainTextColor}
                                    />
                                    <br />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default InboxPage;
