import { IInvoice } from "../Types/InvoiceTypes";

/**
 * Fetches invoices for the logged in user.
 * @param municipalKey string key to indetify municipal.
 * @param ownerId string of numbers 11(ssn) or 9(organisation number) digits long.
 * @param token oidc access token.
 */
async function getInvoices(
  municipalKey: string,
  ownerId: string,
  token: string
): Promise<IInvoice[]> {
  let url = "";
  if (ownerId.trim().length === 9)
    url = `${process.env.REACT_APP_DATA_API}/api/invoice/GetInvoicesForOrganization/${municipalKey}/${ownerId}`;
  else
    url = `${process.env.REACT_APP_DATA_API}/api/invoice/getinvoicesforuser/${municipalKey}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

export { getInvoices };
