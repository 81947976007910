import { createContext, useContext, useEffect, useState } from "react";
import { IUserProfile, KrrData } from "../Types/UserProfileTypes";
import { useUserProfileQuery } from "../Hooks";
import { useSelector } from "react-redux";
import { State } from "../common/reducers";

const defaultState: IUserProfile | undefined = undefined;
const UserProfileContext = createContext<IUserProfile | undefined>(
  defaultState
);

export const UserProfileContextProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [name, setName] = useState("");
  const [krrData, setKrrData] = useState<KrrData>();

  const ssn = useSelector((state: State) => state.auth.user?.profile.pid);

  const { data: userProfile, isPending: isFetchingUserProfile } =
    useUserProfileQuery(ssn);

  useEffect(() => {
    if (userProfile) {
      setName(userProfile.name);
      setKrrData(userProfile.krrData);
    }
  }, [userProfile, isFetchingUserProfile, ssn]);

  const value: IUserProfile = {
    name,
    krrData,
  };

  return (
    <UserProfileContext.Provider value={value}>
      {props.children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = (): IUserProfile => {
  const context = useContext(UserProfileContext);

  if (context === undefined) {
    throw new Error(
      "useUserProfile must be used within a UserProfileContextProvider"
    );
  }

  return context;
};
