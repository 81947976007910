import React from "react";

type IconProps = {
  fillColor?: string;
};

function LinkOutIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      className=" ikta-linkout-icon"
      viewBox="0 0 59.74 61.78"
    >
      <path
        d="M17.02,48.62c-1.17-10.08,1.27-22.6,14.28-22.6h9.78c0,2.27,0,4.54,0,6.8c0,2.12,2.54,3.19,4.04,1.7
			l5.39-5.39l7.73-7.73c0.93-0.93,0.93-2.46,0-3.39l-7.73-7.73L45.12,4.9c-0.75-0.75-1.76-0.85-2.58-0.51
			c-0.89,0.37-1.46,1.24-1.46,2.2c0,2.12,0,4.24,0,6.36c-5.53-0.02-7.43-0.06-11.1,0c-8.19,0.12-18.19,4.16-19.57,15.31
			C8.95,40.16,15.31,47.03,17.02,48.62z"
      />
      <path
        d="M15.34,12.83h-2.87c-3.96,0-7.17,3.21-7.17,7.17v26.28c0,3.96,3.21,7.17,7.17,7.17h25.89
			c3.96,0,7.17-3.21,7.17-7.17v-3.16l4.5-5.45v9.88c0,5.53-4.48,10.01-10.01,10.01H10.81c-5.53,0-10.01-4.48-10.01-10.01V18.73
			c0-5.53,4.48-10.01,10.01-10.01h10.14L15.34,12.83z"
      />
    </svg>
  );
}
export { LinkOutIcon };
