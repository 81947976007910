import React from "react";
import { sortUnitNumbers } from "../../common/utilities/sortFunction";
import { AddressNames } from "../../estates/estateRelation";

const EstateAddresses = (props: { addressNames: AddressNames[] }) => {
  return (
    <div>
      {props.addressNames.map(
        (addressNames: AddressNames, addressIndex: number) => (
          <div key={addressIndex}>
            <h5>{addressNames.text}</h5>
            <h6>Bruksenheter</h6>
            {addressNames.unitNumbers
              .sort((a: string, b: string) => sortUnitNumbers(a, b))
              .map((unitNumber: string, unitIndex: number) => (
                <div key={`${addressIndex}-${unitIndex}`}>
                  <div className="data-value ">{unitNumber}</div>
                </div>
              ))}
            <br />
          </div>
        )
      )}
    </div>
  );
};

export default EstateAddresses;
