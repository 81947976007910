import { IEstate, Document } from './estateRelation';

export const FETCH_ESTATEDATA_BEGIN = 'FETCH_ESTATEDATA_BEGIN';
export const FETCH_ESTATEDATA_SUCCESS = 'FETCH_ESTATEDATA_SUCCESS';
export const FETCH_ESTATEDATA_FAILURE = 'FETCH_ESTATEDATA_FAILURE';
export const FETCH_ESTATEARCHIVE_BEGIN = 'FETCH_ESTATEARCHIVE_BEGIN';
export const FETCH_ESTATEARCHIVE_SUCCESS = 'FETCH_ESTATEARCHIVE_SUCCESS';
export const FETCH_ESTATEARCHIVE_FAILURE = 'FETCH_ESTATEARCHIVE_FAILURE';
export const FETCH_GRUNNBOKPDF_BEGIN = 'FETCH_GRUNNBOKPDF_BEGIN';
export const FETCH_GRUNNBOKPDF_SUCCESS = 'FETCH_GRUNNBOKPDF_SUCCESS';
export const FETCH_GRUNNBOKPDF_FAILURE = 'FETCH_GRUNNBOKPDF_FAILURE';

export const fetchEstateDataBegin = () => ({
  type: FETCH_ESTATEDATA_BEGIN
});

export const fetchEstateDataSuccess = (ownerId: string, estates: IEstate[]) => ({
  type: FETCH_ESTATEDATA_SUCCESS,
  ownerId,
  payload: estates
});

export const fetchEstateDataFailure = (ownerId: string, error: Error) => ({
  type: FETCH_ESTATEDATA_FAILURE,
  ownerId,
  payload: error
});

export const fetchEstateArchiveBegin = () => ({
  type: FETCH_ESTATEARCHIVE_BEGIN,
});

export const fetchEstateArchiveSuccess = (ownerId: string, currentEstate: number, archive: Document[]) => ({
  type: FETCH_ESTATEARCHIVE_SUCCESS,
  currentEstate,
  ownerId,
  payload: archive
});

export const fetchEstateArchiveFailure = (ownerId: string, currentEstate: number, error: Error) => ({
  type: FETCH_ESTATEARCHIVE_FAILURE,
  currentEstate,
  ownerId,
  payload: error
});

export const fetchPropertyGrunnbokPdfBegin = () => ({
  type: FETCH_GRUNNBOKPDF_BEGIN
});

export const fetchPropertyGrunnbokPdfSuccess = (file: File) => ({
  type: FETCH_GRUNNBOKPDF_SUCCESS,
  payload: file
});

export const fetchPropertyGrunnbokPdfFailure = (error: Error) => ({
  type: FETCH_GRUNNBOKPDF_FAILURE,
  payload: { message: error}
});