import { IPropertyTax } from "./propertyTaxRelation";

export const FETCH_PROPERTYTAXDATA_BEGIN = 'FETCH_PROPERTYTAXDATA_BEGIN';
export const FETCH_PROPERTYTAXDATA_SUCCESS = 'FETCH_PROPERTYTAXDATA_SUCCESS';
export const FETCH_PROPERTYTAXDATA_FAILURE = 'FETCH_PROPERTYTAXDATA_FAILURE';
export const RESET_PROPERTYTAX = 'RESET_PROPERTYTAX';

export const fetchPropertyTaxDataBegin = () => ({
  type: FETCH_PROPERTYTAXDATA_BEGIN
});

export const fetchPropertyTaxDataSuccess = (propertyTax: IPropertyTax) =>  ({
  type: FETCH_PROPERTYTAXDATA_SUCCESS,
  payload: propertyTax,
});

export const fetchPropertyTaxDataFailure = (error: Error) => ({
  type: FETCH_PROPERTYTAXDATA_FAILURE,
  payload: error
});

export const resetPropertyTax = () => ({
  type: RESET_PROPERTYTAX
});