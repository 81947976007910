import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface IServiceCardData {
  path: string;
  body: string;
  header: any;
}

export default function ServiceCardView(props: IServiceCardData) {
  return (
    <div
      className="container"
      style={{ width: "22rem", margin: "10px", display: "inline-flex" }}
    >
      <div className="row">
        <Link className="card-link" to={props.path}>
          <Card className="service-card card">
            <Card.Body>
              <h2>{props.header}</h2>
              <div className="cardText">{props.body}</div>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </div>
  );
}
