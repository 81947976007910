import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { getSweepingData } from "../../Requests";

function useSweepingQuery(municipalKey: string) {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useQuery({
    queryKey: ["sweeping", municipalKey],
    queryFn: () => getSweepingData(municipalKey, access_token),
    enabled: !!access_token,
  });
}

export { useSweepingQuery };
