import React from "react";
import { Table } from "react-bootstrap";
import { ISweepingInfo } from "../../Types/SweepingTypes";

const sweepingUrl =
  process.env.REACT_APP_SWEEPING_URL || "/404?REACT_APP_SWEEPING_URL";

type SweepingViewProps = {
  sweepingInfo?: ISweepingInfo;
};

function SweepingView(props: SweepingViewProps) {
  if (!!!props.sweepingInfo?.sweepinginfo)
    return <>Fant ingen pipeløp registrert på dette gårds- og bruksnummeret.</>;
  return (
    <div id="sweepingContainer">
      <p>
        <a href={sweepingUrl} target="_blank" rel="noopener noreferrer">
          Les mer om feiing på kommunens hjemmeside
        </a>
      </p>
      <h2>Frekvens for feiing/tilsyn</h2>
      <div className="nextSweepLabel"></div>
      <div className="nextSweepDate">
        {`Feiing gjennomføres med frekvens på ${props.sweepingInfo.sweepinginfo.chimneys[0].sweepingIntervals} år.`}
        <br />
        {`Tilsyn gjennomføres med frekvens på ${props.sweepingInfo.sweepinginfo.chimneys[0].controlIntervals} år.`}
      </div>
      <div>Du mottar SMS dagen før feier kommer.</div>
      <br />
      <div>
        <h2>Historikk</h2>
        <div id="sweepingReports">
          <Table striped hover size="sm" responsive borderless>
            <thead className="tableHeader">
              <tr>
                <th>Feiing</th>
                <th>Utført dato</th>
                <th>Mengde sot</th>
              </tr>
            </thead>
            <tbody>
              {props.sweepingInfo?.sweepinginfo.reports
                .sort(
                  (a, b) =>
                    new Date(b.dateDone).getTime() -
                    new Date(a.dateDone).getTime()
                )
                .map((report) => {
                  return (
                    <tr key={report.id}>
                      <td style={{ width: "60%" }}>
                        {report.description ?? "Feiing"}
                      </td>
                      <td style={{ width: "20%" }}>
                        {new Date(report.dateDone ?? "").toLocaleDateString(
                          "no-NB",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        )}
                      </td>
                      <td style={{ width: "20%" }}>{report.amountOfSoot}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
export default SweepingView;
