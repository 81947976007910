import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { getUserProfileData } from "../../Requests";

function useUserProfileQuery(ssn: string) {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useQuery({
    queryKey: ["userprofile", ssn],
    queryFn: () => getUserProfileData(ssn, access_token),
    enabled: !!access_token,
  });
}

export { useUserProfileQuery };
