import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { PropertyTaxView } from "../views/propertyTax.view";
import { ICommonPropertyTaxModel } from "../../Types/PropertyTaxTypes";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

type PropertyTaxProps = {
  isTestUser?: boolean;
  propertyTax?: ICommonPropertyTaxModel;
  loading: boolean;
};

export function PropertyTax(props: PropertyTaxProps) {
  const estatePropertyTaxUrl = useSelector(
    (state: State) =>
      state.config.config?.estateTaxService.propertyTaxUrl || undefined
  );

  if (!props.loading) {
    if (!props.propertyTax) {
      return <p>Fant ingen informasjon om din eiendomsskatt</p>;
    } else {
      if (props.propertyTax && props.propertyTax.isVerified) {
        return (
          <PropertyTaxView
            propertyTaxDetails={props.propertyTax.detail}
            showHeader={true}
          />
        );
      } else {
        return (
          <div>
            <p>
              Taksten for denne eiendommen er ikke registrert ferdigbehandlet av
              sakkyndig nemnd. <br />
              Ta kontakt med oss hvis du har spørsmål.{" "}
              {estatePropertyTaxUrl && (
                <a
                  href={estatePropertyTaxUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kontakt eiendomsskattekontoret.
                </a>
              )}
            </p>
          </div>
        );
      }
    }
  } else {
    return (
      <div className="spinner">
        <BootstrapSpinner title="Henter skatter og avgifter" />
      </div>
    );
  }
}
