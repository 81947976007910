import React, { useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { sortUnitNumbers } from "../../common/utilities/sortFunction";
import { Building, Floor, Residence } from "../../estates/estateRelation";

const factor = (type: string) => {
  switch (type) {
    case "Kjelleretasje":
      return -2;
    case "Underetasje":
      return -1;
    case "Hovedetasje":
    default:
      return 1;
    case "Loftetasje":
      return 2;
  }
};

export const sortFloor = (
  a: { floorNumber: number; type: string },
  b: { floorNumber: number; type: string },
  maxFloors: number = 1000
) => {
  return (
    (a.floorNumber + maxFloors) * factor(a.type) -
    (b.floorNumber + maxFloors) * factor(b.type)
  );
};

const EstateBuildings = (props: { buildings: Building[] }) => {
  const [active, setActive] = useState(0);

  const estateBuildingBraUrl = useSelector(
    (state: State) =>
      state.config.config?.estateTaxService?.buildingBraUrl || undefined
  );

  const handleChange = (event: any) => {
    setActive(event.target.value);
  };

  const relevantBuildings = props.buildings
    .filter((building: Building) => building.lopeNr === 0)
    .sort((next: Building, prev: Building) => {
      return parseInt(next.code) - parseInt(prev.code);
    });
  const activeBuilding = relevantBuildings[active];

  return (
    <div className="buildings">
      <div className="row">
        <div className="col-12 mt-1">
          <p className="building-select-title mr-3">Bygning</p>
          <select
            className="building-select"
            value={active}
            onChange={handleChange}
            aria-label="Velg bygning"
          >
            {relevantBuildings.map((build: Building, index: number) => (
              <option
                value={index}
                key={index}
                aria-label={
                  build.buildingNumber + "-" + build.type || build.businessCode
                }
              >
                {build.buildingNumber} - {build.type || build.businessCode}
              </option>
            ))}
          </select>
        </div>
      </div>
      <hr />
      <div className="row building-core-info">
        <div className="col-12 col-sm-8 col-md-6">
          <table className="table table-borderless ikta-table-1-a">
            <thead>
              <tr className="p-0">
                <th className="p-0">
                  <h6>Bygningsinformasjon</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="data-label">Bygningsnr.</td>
                <td className="data-value">{activeBuilding.buildingNumber}</td>
              </tr>
              <tr>
                <td className="data-label">Bygningstype</td>
                <td className="data-value">{activeBuilding.type}</td>
              </tr>
              <tr>
                <td className="data-label">Status</td>
                <td className="data-value status-cell">
                  {activeBuilding.status} <br />{" "}
                  {!activeBuilding.startedUsage ||
                  activeBuilding.startedUsage === "0001-01-01T00:00:00"
                    ? ""
                    : new Date(
                        activeBuilding.startedUsage
                      ).toLocaleDateString()}
                </td>
              </tr>
              <tr>
                <td className="data-label">Bruksareal til bolig</td>
                <td className="data-value">
                  {activeBuilding.totalUsageAreaForResidences + " m²"}
                </td>
              </tr>
              <tr>
                <td className="data-label">
                  Bruksareal til annet <br /> enn bolig
                </td>
                <td className="data-value">
                  {activeBuilding.totalAreaForNotResidences + " m²"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12 col-sm-8 col-lg-4 building-core-info">
          <table className="table table-borderless ikta-table-1-a second-column">
            <tbody>
              <tr>
                <td className="data-label">Etasjer</td>
                <td className="data-value">{activeBuilding.numberOfFloors}</td>
              </tr>
              <tr>
                <td className="data-label">Bruksenheter</td>
                <td className="data-value">
                  {activeBuilding.numberOfResidences}
                </td>
              </tr>
              {activeBuilding.isSefrakHeritage ? (
                <tr>
                  <td className="data-label">SEFRAK-registrert</td>
                  <td className="data-value">Ja</td>
                </tr>
              ) : (
                []
              )}
              {activeBuilding.waterSupply ? (
                <tr>
                  <td className="data-label">Vannforsyning</td>
                  <td className="data-value">{activeBuilding.waterSupply}</td>
                </tr>
              ) : (
                []
              )}
              {activeBuilding.drain ? (
                <tr>
                  <td className="data-label">Avløp</td>
                  <td className="data-value">{activeBuilding.drain}</td>
                </tr>
              ) : (
                []
              )}
            </tbody>
          </table>
        </div>
      </div>
      <h6>Etasjer</h6>
      <div className="row">
        {activeBuilding.floors
          .sort((a: Floor, b: Floor) =>
            sortFloor(a, b, activeBuilding.floors.length)
          )
          .map((floor: Floor, index: number) => (
            <div className="building-cards card card-spacer" key={index}>
              <h5>
                {floor.type} {floor.floorNumber}
              </h5>
              <ul>
                <li>Antall boenheter: {floor.numberOfResidences}</li>
                <li>BRA, bolig: {floor.totalUsageAreaForResidences + " m²"}</li>
                <li>BRA, annet: {floor.totalAreaForNotResidences + " m²"}</li>
                <li>
                  BRA, totalt:{" "}
                  {floor.totalAreaForNotResidences +
                    floor.totalUsageAreaForResidences +
                    " m²"}
                </li>
              </ul>
            </div>
          ))}
      </div>
      <div className="estate-info-text">
        <p>
          <b>Boenhet:</b> Selvstendig og lovlig etablert bolig
        </p>
        {estateBuildingBraUrl && (
          <p>
            <b>BRA:</b>{" "}
            <a
              href={estateBuildingBraUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Slik beregnes bruksareal for eiendomsskatt
            </a>
          </p>
        )}
      </div>
      <h6 className="mt3">Bruksenheter</h6>
      <div className="row">
        {activeBuilding.residences
          .sort((a: Residence, b: Residence) =>
            sortUnitNumbers(a.unitNumber, b.unitNumber)
          )
          .map((residence: Residence, index: number) => (
            <div
              className={
                "building-cards " +
                (residence.seksjonsNr !== 0 && residence.isRequesterOwner
                  ? "card card-spacer estate-owned-section"
                  : "card card-spacer")
              }
              key={index}
            >
              <h5>Bruksenhet – {residence.type}</h5>
              <h6>
                {residence.address &&
                  residence.address.name +
                    (residence.address.number != null
                      ? " " + residence.address.number
                      : "") +
                    (residence.address.letter != null
                      ? " " + residence.address.letter
                      : "")}
              </h6>
              <h6>{residence.unitNumber}</h6>
              {residence.seksjonsNr !== 0 && (
                <h6>Seksjonsnummer: {residence.seksjonsNr}</h6>
              )}
              <ul>
                {residence.seksjonsNr !== 0 && residence.isRequesterOwner && (
                  <li>
                    <b>Eies av deg</b>
                  </li>
                )}
                <li>BRA: {residence.totalUsageArea + " m²" || ""}</li>
                <div style={{ display: "none" }}>
                  <li>Rom: {residence.numberOfRooms || ""}</li>
                  <li>Bad: {residence.numberOfBathrooms || ""}</li>
                  <li>WC: {residence.numberOfWCs || ""}</li>
                  <li>Kjøkken: {residence.kitchenCode || ""}</li>
                </div>
                <li>
                  Etasje:{" "}
                  {residence.floorNumber !== 0
                    ? residence.floorNumber > 9
                      ? "H" + residence.floorNumber
                      : "H0" + residence.floorNumber
                    : 0}
                </li>
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EstateBuildings;
