import { combineReducers } from "redux";
import auth, { AuthState } from "../auth/auth.reducer";
import role, { RoleChooserState } from ".././roleChooser/roleChooser.reducer";
import estate, { EstateState } from "../../estates/estates.reducer";
import organisation, {
  OrganisationState,
} from "../../organisations/organisations.reducer";
import config, { ConfigState } from "../../common/config/config.reducer";

export type State = {
  auth: AuthState;
  role: RoleChooserState;
  estate: EstateState;
  organisation: OrganisationState;
  config: ConfigState;
};

export default combineReducers<State>({
  auth,
  role,
  estate,
  organisation,
  config,
});
