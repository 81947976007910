import {
  fetchEstateDataBegin,
  fetchEstateDataFailure,
  fetchEstateDataSuccess,
  fetchEstateArchiveBegin,
  fetchEstateArchiveSuccess,
  fetchEstateArchiveFailure,
  fetchPropertyGrunnbokPdfBegin,
  fetchPropertyGrunnbokPdfSuccess,
  fetchPropertyGrunnbokPdfFailure,
} from "./estates.actions";

const baseUrl: string =
  process.env.REACT_APP_DATA_API || "/404?REACT_APP_DATA_API";
let endpoint: string;

/**
 * Fetches estate data for a person.
 * @param ownerId string of numbers 11(ssn) or 9(organisation number) digits long.
 * @param access_token access token from identity provider
 */
export function getEstateData(ownerId: string, access_token: string) {
  if (ownerId.length === 9) {
    endpoint = baseUrl.concat(
      "/api/estate/getEstateDataForOrganization/" + ownerId
    );
  } else if (ownerId.length === 11) {
    endpoint = baseUrl.concat("/api/estate/getEstateDataForUser");
  }
  return (dispatch: any) => {
    dispatch(fetchEstateDataBegin());
    return fetch(endpoint, {
      headers: new Headers({ Authorization: "Bearer " + access_token }),
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(fetchEstateDataSuccess(ownerId, json));
        return json;
      })
      .catch((error) =>
        dispatch(fetchEstateDataFailure(ownerId, new Error(error.message)))
      );
  };
}

/**
 * Fetches Archive documents connected to a users estate.
 * @param ownerId string of numbers 11(ssn) or 9(org.nr) digits long
 * @param currentEstate Index of the currently selected estate
 * @param access_token access token from identity provider
 * @param municipalityNumber Municipality number from estate data
 * @param gardsNumber Gardsnumber from estate data
 * @param bruksNumber Bruksnumber from estate data
 * @param seksjonsNumber Seksjonsnumber from estate data
 * @param festeNumber Festenumber from estate data
 */
export function getEstateArchive(
  ownerId: string,
  currentEstate: number,
  access_token: string,
  municipalityNumber: string,
  gardsNumber: number,
  bruksNumber: number,
  seksjonsNumber: number,
  festeNumber: number
) {
  return (dispatch: any) => {
    dispatch(fetchEstateArchiveBegin());
    return fetch(
      baseUrl.concat(
        "/api/estate/getDocumentsForEstate/" +
          municipalityNumber +
          "/" +
          gardsNumber +
          "/" +
          bruksNumber +
          "/" +
          seksjonsNumber +
          "/" +
          festeNumber
      ),
      {
        headers: new Headers({ Authorization: "Bearer " + access_token }),
      }
    )
      .then((response: Response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(fetchEstateArchiveSuccess(ownerId, currentEstate, json));
        return json;
      })
      .catch((error) => {
        dispatch(
          fetchEstateArchiveFailure(
            ownerId,
            currentEstate,
            new Error(error.message)
          )
        );
      });
  };
}

export function getGrunnbokPdfForEstate(
  access_token: string,
  municipalityNumber: string,
  gardsNumber: number,
  bruksNumber: number,
  seksjonsNumber: number,
  festeNumber: number
) {
  return (dispatch: any) => {
    dispatch(fetchPropertyGrunnbokPdfBegin());
    return fetch(
      baseUrl.concat(
        `/api/Estate/GetGrunnbokPdf/${municipalityNumber}/${gardsNumber}/${bruksNumber}/${seksjonsNumber}/${festeNumber}`
      ),
      {
        headers: new Headers({ Authorization: "Bearer " + access_token }),
      }
    )
      .then((res: Response) => {
        if (!!!res.ok) {
          throw new Error();
        }
        return res.blob();
      })
      .then((response: any) => {
        var objectURL = URL.createObjectURL(response);

        let tab = window.open();
        if (tab) tab.location.href = objectURL;

        dispatch(fetchPropertyGrunnbokPdfSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(fetchPropertyGrunnbokPdfFailure(new Error(error.message)));
      });
  };
}
