import { IMunicipalityConfig } from './municipalityConfig';

export const FETCH_CONFIGDATA_BEGIN = 'FETCH_CONFIGDATA_BEGIN';
export const FETCH_CONFIGDATA_SUCCESS = 'FETCH_CONFIGDATA_SUCCESS';
export const FETCH_CONFIGDATA_FAILURE = 'FETCH_CONFIGDATA_FAILURE';

export const fetchConfigDataBegin = () => ({
  type: FETCH_CONFIGDATA_BEGIN
});

export const fetchConfigDataSuccess = (userprofile: IMunicipalityConfig) => ({
  type: FETCH_CONFIGDATA_SUCCESS,
  payload: userprofile
});

export const fetchConfigDataFailure = (error: Error) => ({
  type: FETCH_CONFIGDATA_FAILURE,
  payload: error
});