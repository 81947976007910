import React from "react";
import { ProfileService } from "../../common/config/municipalityConfig";
import { KrrData } from "../../Types/UserProfileTypes";
import { Col } from "react-bootstrap";
import { toPascalCase } from "../../common/utilities/parser";
import { LinkOutIcon } from "../../Icons/LinkOutIcon";
import Variables from "../../customStyles/_export.scss";

const KrrDataView = (props: {
  profile?: KrrData;
  profileService?: ProfileService;
}) => {
  if (!!!props.profile)
    return (
      <>
        <p>Fant ingen profil!</p>
      </>
    );

  return (
    <>
      <Col>
        <p>
          <b>E-post:</b> {props.profile.kontaktinformasjon.epostadresse}
        </p>
        <p>
          <b>Mobil:</b> {props.profile.kontaktinformasjon.mobiltelefonnummer}
        </p>
        <p>
          <b>Reservasjon mot kommunikasjon på nett?:</b>{" "}
          {toPascalCase(props.profile.reservasjon)}
        </p>
        <br />
        <a href={props.profileService?.registerUrl} target="_new">
          Gå til KRR for å endre din profil
        </a>{" "}
        <LinkOutIcon fillColor={Variables.mainTextColor} />
        <h2>Om reservasjon mot kommunikasjon på nett</h2>
        <p>{props.profileService?.reservationText}</p>
        <a href={props.profileService?.digdirUrl} target="_new">
          Les mer om reservasjon hos Digitaliseringsdirektoratet
        </a>{" "}
        <LinkOutIcon fillColor={Variables.mainTextColor} />
      </Col>
    </>
  );
};
export default KrrDataView;
