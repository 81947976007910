import { 
    fetchRoleChooserDataBegin, fetchRoleChooserDataSuccess,

} from "./roleChooser.actions";

const endpoint = process.env.REACT_APP_ROLE_API || '/404?REACT_APP_ROLE_API';

export function getRolesData(access_token: string) {
    return (dispatch: Function) => {
        dispatch(fetchRoleChooserDataBegin());
        return fetch(endpoint.concat('/api/roles/getrolesforuser'), { headers: new Headers({"Authorization": "Bearer " + access_token}) })
            .then(res => {
                return res.json();
            })
            .then(json => {
                dispatch(fetchRoleChooserDataSuccess(json));
            })
            .catch((error: Error) => {
                console.log(error);
            })
    }
}