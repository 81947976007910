import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { Col, Row } from "react-bootstrap";
import KrrDataView from "./KrrDataView";
import { useUserProfile } from "../../Contexts/UserProfile";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

const UserProfilePage = () => {
  const userProfileService = useSelector(
    (state: State) => state.config.config?.profileService || undefined
  );

  const userProfile = useUserProfile();

  return (
    <>
      <div className="container profile-container">
        <Row>
          <Col xs={{ span: 12 }}>
            <section>
              <h1>Min profil</h1>
              {userProfileService?.basicConfig.hideService ? (
                "Tjenesten er dessverre ikke tilgjengelig."
              ) : (
                <>
                  {userProfileService?.basicConfig.alertText &&
                    userProfileService?.basicConfig.alertText !== "" && (
                      <div className="infoBoxLanding">
                        <p>{userProfileService?.basicConfig.alertText}</p>
                      </div>
                    )}
                  <p>{userProfileService?.basicConfig.ingressText}</p>
                  {userProfile ? (
                    userProfile ? (
                      <KrrDataView
                        profile={userProfile.krrData}
                        profileService={userProfileService}
                      />
                    ) : (
                      <p>{userProfileService?.basicConfig.notFoundText}</p>
                    )
                  ) : (
                    <>
                      <BootstrapSpinner />
                    </>
                  )}
                </>
              )}
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default UserProfilePage;
