import { ISweepingInfo } from "../Types/SweepingTypes";

/**
 * Fetches sweepingdata for the users estates.
 * @param municipalKey string determing municipality.
 * @param token oidc access token.
 */
async function getSweepingData(
  municipalKey: string,
  token: string
): Promise<ISweepingInfo[]> {
  const url = `${process.env.REACT_APP_DATA_API}/api/Estate/GetEstateSweepingData/${municipalKey}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

export { getSweepingData };
