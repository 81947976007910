import React from "react";
import HeaderContainer from "./containers/header";
import BreadcrumbView from "../breadcrumb/breadcrumb.view";
import Footer from "./containers/footer";
import { Outlet } from "react-router-dom";

function Root() {
  return (
    <div>
      <HeaderContainer />
      <BreadcrumbView />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Root;
