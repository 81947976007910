import { IUserProfile } from "../Types/UserProfileTypes";

/**
 * Fetches logged in user profile.
 * @param ssn string social security number.
 * @param token oidc access token.
 */
async function getUserProfileData(
  ssn: string,
  token: string
): Promise<IUserProfile> {
  const url = `${process.env.REACT_APP_DATA_API}/api/userprofile/${ssn}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return await response.json();
}

export { getUserProfileData };
