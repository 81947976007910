import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { PropertyTaxViewProp } from "./PropertyTaxDetailsKomTek";
import { AccordionView } from "../../common/accordion/accordion.view";
import { toLocaleWithDotSeperator } from "../../common/utilities/parser";

export function PropertyTaxDetails(props: PropertyTaxViewProp) {
  const estatePropertyTaxUrl = useSelector(
    (state: State) =>
      state.config.config?.estateTaxService.propertyTaxUrl || undefined
  );

  if (!!!props.propertyTaxDetails)
    return (
      <>Fant ingen eiendomsskatt registrert på dette gårds- og bruksnummeret.</>
    );

  return (
    <div className="container p-0">
      <div id="propertyTaxContainer">
        {props.showHeader ? (
          <>
            <div className="row">
              <div className="rate-container">
                <h3>
                  Vedtatt eiendomsskatt ={" "}
                  {props.propertyTaxDetails.calculatedTaxTotal} kr pr. år.
                </h3>
              </div>
            </div>
            <h4 className="mt-5">Slik beregnes taksten</h4>
          </>
        ) : (
          <>
            <h2>Beregning</h2>
            <h3>Slik beregnes taksten</h3>
          </>
        )}

        <p>
          Taksten er resultatet av en vurdering av din eiendom. Taksten skal
          samsvare med markedsverdien på eiendommen din.
          <br />
          Vi bruker informasjon om eiendommen fra matrikkelen (Norges offisielle
          eiendomsregister, med informasjon om eiendomsgrenser, areal,
          bygninger, boliger og adresser) sammen med egne vurderinger når vi
          beregner taksten.
          <br />
          <br />
          Taksten regnes ut denne måten:
        </p>
        <div className="taxFormula">
          Sjablongtakst * Sonefaktor * Indre vurderingsfaktor * Ytre
          vurderingsfaktor = Takst
        </div>
        <ul>
          <li>
            Sjablontakst: Denne regnes ut fra bestemte forhold som kvadratmeter
            og areal. Taksten er uavhengig av f.eks. beliggenhet og tilstand.
          </li>
          <li>
            Sonefaktor: Sonefaktor for boligeiendom skal fange opp forskjeller i
            omsetningsverdi for fast eiendom i kommunen.
          </li>
          <li>
            Indre vurderingsfaktor: Faktor for indre forhold benyttes for å
            korrigere sjablongtaksten ut fra forhold på eiendommen.
            Funksjonalitet, standard og kvalitet kan variere fra eiendom til
            eiendom.
          </li>
          <li>
            Ytre vurderingsfaktor: Faktor for ytre forhold benyttes for å
            korrigere sjablongtaksten ut fra forhold rundt eiendommen. Det
            gjøres en skjønnsmessig vurdering ved besiktigelse.
          </li>
        </ul>

        <h3>Slik beregnes eiendomsskatten</h3>
        <p>
          Fra 2020 sier lov om eiendomsskatt at taksten på bolig- og
          fritidseiendommer skal reduseres med 30 %. Det er ingen obligatorisk
          reduksjon på andre typer eiendommer. Det er kommunestyret som vedtar
          hvilken reduksjon, skattesats (promille) og ev. bunnfradrag vi skal ha
          i vår kommune.
        </p>
        <Col lg={{ span: 6 }} xs={{ span: 12 }} className="p-0">
          <table className="tax-calculation">
            <tbody>
              <tr className="calculation-row">
                <th>Takst:</th>
                <td>
                  {props.propertyTaxDetails.valuationTotal
                    ? toLocaleWithDotSeperator(
                        props.propertyTaxDetails.valuationTotal
                      )
                    : ""}{" "}
                  kr
                </td>
              </tr>
              <tr className="calculation-row">
                <th>- Obligatorisk reduksjonsverdi:</th>
                <td>
                  {props.propertyTaxDetails.reductionTotal
                    ? toLocaleWithDotSeperator(
                        props.propertyTaxDetails.reductionTotal
                      )
                    : ""}{" "}
                  kr
                </td>
              </tr>
              <tr className="calculation-row">
                <th>- Fritak:</th>
                <td>
                  {props.propertyTaxDetails.exemptionTotal
                    ? toLocaleWithDotSeperator(
                        props.propertyTaxDetails.exemptionTotal
                      )
                    : ""}{" "}
                  kr
                </td>
              </tr>
              <tr className="calculation-row">
                <th>- Bunnfradrag:</th>
                <td>
                  {props.propertyTaxDetails.deductionTotal
                    ? toLocaleWithDotSeperator(
                        props.propertyTaxDetails.deductionTotal
                      )
                    : ""}{" "}
                  kr
                </td>
              </tr>
              <tr className="sum">
                <th>= Skattegrunnlag:</th>
                <td>
                  {props.propertyTaxDetails.taxBasisTotal
                    ? toLocaleWithDotSeperator(
                        props.propertyTaxDetails.taxBasisTotal
                      )
                    : ""}{" "}
                  kr
                </td>
              </tr>
              <tr className="calculation-row">
                <th>* Skattesats:</th>
                <td>{props.propertyTaxDetails.thousandRate} ‰</td>
              </tr>
              <tr className="sum">
                <th className="font-weight-bold">= Eiendomsskatt pr. år:</th>
                <td className="font-weight-bold">
                  {props.propertyTaxDetails.calculatedTaxTotal
                    ? toLocaleWithDotSeperator(
                        props.propertyTaxDetails.calculatedTaxTotal
                      )
                    : ""}{" "}
                  kr
                </td>
              </tr>
              <tr className="calculation-row">
                <td colSpan={2}>
                  <div className="double-line"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <br />
        <Col
          lg={{ span: 5 }}
          xs={{ span: 12 }}
          className="p-0"
          style={{ width: "100%" }}
        >
          <AccordionView
            showNarrow={true}
            hideBorder={true}
            content={[
              {
                title: "Grunnlag for takst",
                content: (
                  <>
                    {props.propertyTaxDetails.taxZoneItems.map(
                      (taxZone, index) => (
                        <div key={index}>
                          <h3>{taxZone.description}</h3>
                          <table key={index} className="tax-calculation">
                            <tbody>
                              <tr className="calculation-row">
                                <th>Sjablongtakst (inkl sonefaktor):</th>
                                <td>
                                  {toLocaleWithDotSeperator(
                                    taxZone.templateTaxTotal.replaceAll(
                                      "&nbsp;",
                                      ""
                                    )
                                  )}{" "}
                                  kr
                                </td>
                              </tr>
                              <tr className="calculation-row">
                                <th>* Indre vurderingsfaktor:</th>
                                <td>{taxZone.innerFactor}</td>
                              </tr>
                              <tr className="calculation-row">
                                <th>* Ytre vurderingsfaktor:</th>
                                <td>{taxZone.outerFactor}</td>
                              </tr>
                              <tr className="sum">
                                <th className="font-weight-bold">= Takst:</th>
                                <td className="font-weight-bold">
                                  {toLocaleWithDotSeperator(
                                    taxZone.valuationTotal
                                  )}{" "}
                                  kr
                                </td>
                              </tr>
                              <tr className="calculation-row">
                                <td colSpan={2}>
                                  <div className="double-line"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                        </div>
                      )
                    )}
                  </>
                ),
              },
            ]}
          />
        </Col>
        {estatePropertyTaxUrl && (
          <>
            <br />
            <h3>Har du spørsmål?</h3>
            <p>
              På vår{" "}
              <a href={estatePropertyTaxUrl}>nettside om eiendomsskatt</a> kan
              du lese mer om eiendomsskatt. Her finner du også kontaktinfo og
              skjema for klage på takst.
            </p>
          </>
        )}
      </div>
    </div>
  );
}
