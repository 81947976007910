import React from 'react';
import TabHeader from './tabHeader.view';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { ITabCollection } from '../interfaces/tabCollection';

type TabviewState = {
    key: number;
};

type TabviewProps = {
    collection: any[];
    defaultIndex?: number;
    onEntered?: Function;
    onExit?: Function;
};

export default class Tabview extends React.Component<
    TabviewProps,
    TabviewState
    > {
    constructor(props: any) {
        super(props);
        this.state = {
            key: this.props.defaultIndex || 0
        };
    }

    createTabHeaders(tabCollection: ITabCollection[]) {
        return tabCollection.map((tabviewObj: ITabCollection, index: number) => (
            <TabHeader
                onClick={() => {
                    this.setState({ key: index })
                }}
                key={index}
                title={tabviewObj.header}
                className={index === this.state.key && 'active'} />
        ));
    }
    createTabContent(tabCollection: ITabCollection[]) {
        return tabCollection.map((tableviewObj: ITabCollection, index: number) => (
            <Tab.Pane 
              eventKey={index} 
              key={index} 
              onEntered={() => {
                this.props.onEntered && this.props.onEntered(index);
              }}
              onExit={() => {
                this.props.onExit && this.props.onExit(index);
              }}
            >
            {tableviewObj.body}
            </Tab.Pane>
        ));
    }

    render() {
        const { collection } = this.props;
        const activeKey = this.state.key;
        return (
          <Tab.Container activeKey={activeKey} onSelect={() => null} mountOnEnter={true}>
            <Row>
              <Col xs={12} className="ikta-tab-navigation d-flex">
                <div className="mt-auto">
                  <Nav variant='tabs'> {this.createTabHeaders(collection)} </Nav>
                </div>
              </Col>
            </Row>
            <Row className="ikta-tab-navigation-content">
              <Col className="col-12">
                <Tab.Content>{this.createTabContent(collection)}</Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        );
      }
    }