import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { useSweepingQuery } from "../../Hooks";
import { IAccordionData } from "../../common/interfaces/accordionData";
import { AccordionView } from "../../common/accordion/accordion.view";
import { Col, Row } from "react-bootstrap";
import SweepingData from "./SweepingData";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

const SweepingPage = () => {
  const municipalKey =
    process.env.REACT_APP_MUNICIPALITY_NAME ||
    "/404?REACT_APP_MUNICIPALITY_NAME";

  const sweepingService = useSelector(
    (state: State) => state.config.config?.sweepingService || undefined
  );

  const { data: sweepingItems, isPending: isFetchingSweepingItems } =
    useSweepingQuery(municipalKey);

  const createAccordionData = (): IAccordionData[] => {
    let accordionData: { title: string; content: JSX.Element }[] = [];

    if (sweepingItems && sweepingItems) {
      sweepingItems.map((data: any) => {
        return accordionData.push({
          title:
            data.address.streetName ??
            `${data.farmNumber} / ${data.utilityNumber} / ${data.sectionNumber} / ${data.lotNumber}`,
          content: <SweepingData sweepingInfo={data} />,
        });
      });
    }

    return accordionData;
  };

  return (
    <>
      <div className="container sweeping-container">
        <Row>
          <Col xs={{ span: 12 }}>
            <section>
              <h1>Feiing og boligtilsyn</h1>
              {sweepingService?.basicConfig.hideService ? (
                "Tjenesten er dessverre ikke tilgjengelig"
              ) : (
                <>
                  {sweepingService?.basicConfig.alertText &&
                    sweepingService?.basicConfig.alertText !== "" && (
                      <div className="infoBoxLanding">
                        <p>{sweepingService?.basicConfig.alertText}</p>
                      </div>
                    )}
                  <p>{sweepingService?.basicConfig.ingressText}</p>
                  <div>
                    {!isFetchingSweepingItems ? (
                      sweepingItems ? (
                        sweepingItems.length !== 0 ? (
                          <AccordionView
                            content={createAccordionData()}
                            hideBorder={true}
                          />
                        ) : (
                          sweepingService?.basicConfig.notFoundText
                        )
                      ) : (
                        "Tjenesten er dessverre ikke tilgjengelig"
                      )
                    ) : (
                      <BootstrapSpinner title="Henter din feiing..." />
                    )}
                  </div>
                </>
              )}
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SweepingPage;
