import React, { useEffect } from "react";
import { login, completeLogin } from "../auth.actions";
import isAuthenticated from "../isAuthenticated";
import { State } from "../../reducers";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BootstrapSpinner } from "../../spinners/bootstrapSpinner";

function Login(props: { myLocation: any }) {
  let dispatch = useDispatch();
  let location = useLocation();

  const userAuthenticated = useSelector((state: State) =>
    isAuthenticated(state.auth.user)
  );

  const error = useSelector((state: State) => state.auth.error);
  const loading = useSelector((state: State) => state.auth.loading);

  useEffect(() => {
    if (location.pathname === "/login/response") {
      dispatch<any>(completeLogin());
      JSON.parse(localStorage.getItem("state") || "null");
    } else if (!userAuthenticated) {
      localStorage.setItem(
        "state",
        JSON.stringify(props.myLocation.location.pathname)
      );
      dispatch<any>(login());
    }
  }, [
    dispatch,
    location,
    userAuthenticated,
    props.myLocation.location.pathname,
  ]);

  let from = sessionStorage.getItem("location") || "/";

  if (userAuthenticated) {
    return <Navigate to={from} />;
  } else if (error) {
    return <div className="ErrorMsg">{error.message}</div>;
  } else if (loading) {
    return <BootstrapSpinner title="Autentiserer" />;
  } else {
    return <p>Du er ikke logget inn</p>;
  }
}

export default Login;
