import { ICompanyInfo } from './organisation';

export const FETCH_ORGANISATIONDATA_BEGIN = 'FETCH_ORGANISATIONDATA_BEGIN'
export const FETCH_ORGANISATIONDATA_SUCCESS = 'FETCH_ORGANISATIONDATA_SUCCESS'
export const FETCH_ORGANISATIONDATA_FAILURE = 'FETCH_ORGANISATIONDATA_FAILURE'

export const fetchOrganisationDataBegin = () => ({
  type: FETCH_ORGANISATIONDATA_BEGIN
});

export const fetchOrganisationDataSuccess = (organisations: ICompanyInfo[]) => ({
  type: FETCH_ORGANISATIONDATA_SUCCESS,
  payload: organisations
});

export const fetchOrganisationDataFailure = (error: Error) => ({
  type: FETCH_ORGANISATIONDATA_FAILURE,
  payload: error
});