import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../../reducers";
import Variables from "../../../customStyles/_export.scss";
import { RoleContainer } from "../../roleChooser/containers/role";

function Header(): ReactElement {
  const header = useSelector(
    (state: State) => state.config.config?.header || undefined
  );

  return (
    <div className="container header-container">
      <Link to="/">
        <img
          src={header?.logoUrl}
          alt={header?.logoAltText}
          id="logo"
          height={105}
        />
      </Link>
      <div className="header-right">
        <RoleContainer />
        <Link to="/logout" id="logout">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6667 2.78242C11.4282 1.67399 9.79283 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C9.79283 15 11.4282 14.326 12.6667 13.2176"
              stroke={Variables.mainTextColor}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="9"
              y1="8"
              x2="18"
              y2="8"
              stroke={Variables.mainTextColor}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M15.5 5L18.5 8L15.5 11"
              stroke={Variables.mainTextColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Logg ut
        </Link>
      </div>
    </div>
  );
}
export default Header;
