import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./common/configureStore";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserProfileContextProvider } from "./Contexts/UserProfile";

const store = configureStore();

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <UserProfileContextProvider>
        <App />
      </UserProfileContextProvider>
    </Provider>
  </QueryClientProvider>
);
serviceWorker.unregister();
