/**
 * Converts Date to string with format DD.MM.YYYY
 * @param date Date object
 */
export const parseDateToString = (date: Date) => {
  const months: string[] = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "12",
  ];
  let datestring =
    (date.getDate() < 9 ? "0" + date.getDate() : date.getDate()) +
    "." +
    months[date.getMonth()] +
    "." +
    date.getFullYear();

  return datestring;
};

export function toPascalCase(value: string) {
  return `${value}`
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, "g"), " ")
    .replace(new RegExp(/[^\w\s]/, "g"), "")
    .replace(
      new RegExp(/\s+(.)(\w*)/, "g"),
      ($1, $2, $3) => `${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

export function toCurrency(amount: string) {
  const formatter = new Intl.NumberFormat("no", {
    style: "currency",
    currency: "NOK",
  });

  return formatter.format(Number(amount));
}

export function toLocaleWithDotSeperator(value: string) {
  return Number(value.replace("kr ", ""))
    .toLocaleString("no-NB")
    .replaceAll(/\s/g, ".");
}
