import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import Variables from "../../customStyles/_export.scss";
import { HomeIcon } from "./Icons/HomeIcon";
import { ArrowLeftIcon } from "./Icons/ArrowLeftIcon";
import { HomeIconOutline } from "./Icons/HomeIconOutline";

const formatCebabCaseString = (cebab: string) => {
  let name = cebab.charAt(0).toUpperCase() + cebab.slice(1);
  return name.replaceAll("-", " ");
};

function BreadcrumbView() {
  let location = useLocation();

  const getBreadcrumbs = () => {
    if (location.pathname !== "/") {
      let path = location.pathname.split("/");
      let absolutePath = "";
      var breadcrumbs = path.map((p: string, index: number) => {
        if (index === 0)
          return (
            <Link
              key={index}
              to="/"
              aria-label="Hjem"
              title="Hjem"
              className="mainLinkBread"
            >
              <HomeIconOutline fillColor={Variables.mainTextColor} />
            </Link>
          );
        else if (index !== path.length - 1) {
          absolutePath += "/" + p;
          return (
            <Link key={index} to={absolutePath}>
              <div className="separator">
                <ArrowLeftIcon fillColor={Variables.mainTextColor} />
              </div>

              {formatCebabCaseString(p)}
            </Link>
          );
        } else
          return (
            <Breadcrumb.Item key={index} active>
              <div className="separator">
                <ArrowLeftIcon fillColor={Variables.mainTextColor} />
              </div>
              {formatCebabCaseString(p)}
            </Breadcrumb.Item>
          );
      });
      return breadcrumbs;
    }
  };

  return (
    <div className="container">
      <Row>
        <Col xs={{ span: 10 }}>
          <Breadcrumb>{getBreadcrumbs()}</Breadcrumb>
        </Col>
      </Row>
    </div>
  );
}

export default BreadcrumbView;
