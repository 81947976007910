import { useDispatch, useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { CadastreIdentity } from "../../estates/estateRelation";
import { getGrunnbokPdfForEstate } from "../../estates/estates.service";
import { LinkOutIcon } from "../../Icons/LinkOutIcon";
import StyleVariables from "../../customStyles/_export.scss";

const EstateGrunnbok = (props: { matrikkelenhetIdent: CadastreIdentity }) => {
  const dispatch = useDispatch();

  const accessToken = useSelector((state: State) =>
    state.auth.user ? state.auth.user.access_token : ""
  );

  const loadingGetPdf = useSelector(
    (state: State) => state.estate.loadingGetPdf
  );
  const loadingGetPdfFailed = useSelector(
    (state: State) => state.estate.loadingGetPdfFailed
  );

  let seksjonsnr = props.matrikkelenhetIdent.seksjonsnr
    ? props.matrikkelenhetIdent.seksjonsnr
    : 0;
  let festenr = props.matrikkelenhetIdent.festenr
    ? props.matrikkelenhetIdent.festenr
    : 0;
  return (
    <>
      <p>
        Grunnboken er et offentlig register, som viser tinglyste rettigheter og
        forpliktelser i eiendom. Grunnboken var tidligere en bok med håndskrevne
        ark, og er i dag et dataregister som forvaltes av Kartverket.
      </p>
      <button
        className="ikta-button"
        disabled={loadingGetPdf}
        onClick={() =>
          dispatch<any>(
            getGrunnbokPdfForEstate(
              accessToken,
              props.matrikkelenhetIdent.kommunenr,
              props.matrikkelenhetIdent.gardsnr,
              props.matrikkelenhetIdent.bruksnr,
              seksjonsnr,
              festenr
            )
          )
        }
      >
        {loadingGetPdf ? "Henter grunnbokutskrift" : "Åpne grunnbokutskrift"}
        <div className="ikta-linkout">
          <LinkOutIcon fillColor={StyleVariables.footerTextColor} />
        </div>
      </button>
      {loadingGetPdfFailed ? <p>Kunne ikke hente grunnboksutskrift</p> : ""}
    </>
  );
};
export default EstateGrunnbok;
