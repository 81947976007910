import {
        FETCH_ORGANISATIONDATA_BEGIN,
        FETCH_ORGANISATIONDATA_FAILURE,
        FETCH_ORGANISATIONDATA_SUCCESS
    } from './organisations.actions'
    import { ICompanyInfo } from './organisation';
    
    export type OrganisationState = {
        organisations?: ICompanyInfo[];
        loading: boolean;
        error?: string;
    }
    
    export default function organisationReducer(
        state: OrganisationState = {
        loading: false
        },
        action: any
    ) {
        switch (action.type) {
        case FETCH_ORGANISATIONDATA_BEGIN:
            return {
            ...state,
            loading: true,
            error: undefined
            };
        case FETCH_ORGANISATIONDATA_SUCCESS:
            return {
            ...state,
            loading: false,
            organisations: action.payload
            };
        case FETCH_ORGANISATIONDATA_FAILURE:
            return {
            ...state,
            loading: false,
            error: action.payload,
            organisations: []
            };
        default:
            return state;
        }
}