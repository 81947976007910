import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toCurrency } from "../../common/utilities/parser";
import { useInvoiceQuery } from "../../Hooks";
import { ChevronLeftIcon } from "../../Icons/ChevronLeftIcon";
import Variables from "../../customStyles/_export.scss";
import { ChevronRightIcon } from "../../Icons/ChevronRightIcon";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { getOwnerId } from "../../common/utilities/roleHelpers";
import { IInvoice } from "../../Types/InvoiceTypes";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";
const municipalKey =
  process.env.REACT_APP_MUNICIPALITY_NAME || "/404?REACT_APP_MUNICIPALITY_NAME";

const InvoicePage = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearPrevValid, setYearPrevValid] = useState(true);
  const [yearNextValid, setYearNextValid] = useState(false);
  const [years] = useState<number[]>([]);

  const activeRole = useSelector((state: State) => state.role.activeRole);
  const ownerId = getOwnerId(activeRole);

  const invoiceService = useSelector(
    (state: State) => state.config.config?.invoiceService || undefined
  );

  const { data: invoiceItems, isPending: isFetchingIvoiceItems } =
    useInvoiceQuery(municipalKey, ownerId);

  useEffect(() => {
    if (invoiceItems) {
      invoiceItems.forEach((x) => {
        if (!!!years.includes(new Date(x.invoiceDate).getFullYear()))
          years.push(new Date(x.invoiceDate).getFullYear());
      });
    }
  }, [invoiceItems, years]);

  function setPrevious(): void {
    setYear(year - 1);
    setYearPrevValid(true);
    if (!!!years.includes(year - 2)) {
      setYearPrevValid(false);
    }
    setYearNextValid(true);
  }

  function setNext(): void {
    setYear(year + 1);
    setYearNextValid(true);
    if (!!!years.includes(year + 2)) {
      setYearNextValid(false);
    }
    setYearPrevValid(true);
  }

  return (
    <>
      <div className="container invoices-container">
        <h1>Fakturaer</h1>
        {isFetchingIvoiceItems ? (
          <>
            <BootstrapSpinner />
          </>
        ) : invoiceService?.basicConfig.hideService ? (
          <div className="ingress">
            Tjenesten er dessverre ikke tilgjengelig.
          </div>
        ) : (
          <>
            {invoiceService?.basicConfig.alertText &&
              invoiceService?.basicConfig.alertText !== "" && (
                <div className="infoBoxLanding">
                  <p>{invoiceService?.basicConfig.alertText}</p>
                </div>
              )}
            {invoiceItems && invoiceItems.length >= 1 ? (
              <>
                <div>
                  <div className="ingress">
                    {invoiceService?.basicConfig.ingressText}
                  </div>
                  <div className="filter">
                    <Button
                      disabled={!!!yearPrevValid}
                      variant="link"
                      className="invoicePrevYear"
                      onClick={() => setPrevious()}
                      title="Forrige"
                      aria-label="Forrige"
                    >
                      <ChevronLeftIcon fillColor={Variables.mainTextColor} />
                    </Button>
                    {year}
                    <Button
                      disabled={!!!yearNextValid}
                      variant="link"
                      className="invoiceNextYear"
                      onClick={() => setNext()}
                      style={{ fontWeight: "bold" }}
                      title="Neste"
                      aria-label="Neste"
                    >
                      <ChevronRightIcon fillColor={Variables.mainTextColor} />
                    </Button>
                  </div>
                </div>
                <div className="invoice-list">
                  {invoiceItems
                    ?.filter(
                      (f) => new Date(f.invoiceDate).getFullYear() === year
                    )
                    .sort((a: IInvoice, b: IInvoice) => {
                      if (b.invoiceDate > a.invoiceDate) return 1;
                      return -1;
                    })
                    .map((item, index) => {
                      return (
                        <div className="invoice-item" key={index}>
                          <div className="invoiceLeft">
                            <h2>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                title="Åpne faktura"
                                aria-label="Last ned faktura"
                              >
                                {item.name
                                  ? `${item.name} (${item.invoiceNumber})`
                                  : item.invoiceNumber}
                              </a>
                            </h2>
                            {new Date(item.invoiceDate).toLocaleDateString(
                              "no",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                          </div>

                          <div className="invoiceRight">
                            <div className="invoiceAmount">
                              {toCurrency(item.amount)}
                            </div>
                            <div className="invoiceDueDate">
                              {item.isFullyPaid
                                ? "Betalt"
                                : `innen ${new Date(
                                    item.dueDate
                                  ).toLocaleDateString("no", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}`}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <div className="no-invoice-item">
                {invoiceService?.basicConfig.notFoundText}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InvoicePage;
