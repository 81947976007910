import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { usePropertyTaxQuery } from "../../Hooks";
import { getOwnerId } from "../../common/utilities/roleHelpers";
import { IAccordionData } from "../../common/interfaces/accordionData";
import { AccordionView } from "../../common/accordion/accordion.view";
import { Col, Row } from "react-bootstrap";
import { PropertyTaxDetails } from "./PropertyTaxDetails";
import { PropertyTaxDetailsKomTek } from "./PropertyTaxDetailsKomTek";
import { BootstrapSpinner } from "../../common/spinners/bootstrapSpinner";

const PropertyTaxPage = () => {
  const municipalKey =
    process.env.REACT_APP_MUNICIPALITY_NAME ||
    "/404?REACT_APP_MUNICIPALITY_NAME";

  const activeRole = useSelector((state: State) => state.role.activeRole);
  const ownerId = getOwnerId(activeRole);

  const estateTaxService = useSelector(
    (state: State) => state.config.config?.estateTaxService || undefined
  );

  const { data: propertyTaxItems, isPending: isFetchingPropertyTaxItems } =
    usePropertyTaxQuery(municipalKey, ownerId);

  const createAccordionData = (): IAccordionData[] => {
    let accordionData: { title: string; content: JSX.Element }[] = [];

    if (propertyTaxItems) {
      propertyTaxItems.map((data) => {
        return accordionData.push({
          title: data.title ?? "",
          content: (
            <>
              {propertyTaxItems &&
              propertyTaxItems.length > 0 &&
              propertyTaxItems[0].isVerified ? (
                <>
                  <h2>Vedtatt eiendomsskatt</h2>
                  <p className="ingress">
                    Pris pr. år:{" "}
                    {data.detail.calculatedTaxTotal.replace("kr ", "")} kr.
                  </p>
                  <AccordionView
                    showNarrow={true}
                    hideBorder={true}
                    content={[
                      {
                        title: "Se beregning",
                        content:
                          municipalKey === "arendal" ||
                          municipalKey === "froland" ? (
                            <PropertyTaxDetailsKomTek
                              propertyTaxDetails={data.detail}
                              showHeader={false}
                            />
                          ) : (
                            <PropertyTaxDetails
                              propertyTaxDetails={data.detail}
                              showHeader={false}
                            />
                          ),
                      },
                    ]}
                  />{" "}
                </>
              ) : (
                <div>
                  <p>
                    Taksten for denne eiendommen er ikke registrert
                    ferdigbehandlet av sakkyndig nemnd. <br />
                    Ta kontakt med oss hvis du har spørsmål.{" "}
                    {estateTaxService?.propertyTaxUrl && (
                      <a
                        href={estateTaxService?.propertyTaxUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Kontakt eiendomsskattekontoret.
                      </a>
                    )}
                  </p>
                </div>
              )}
            </>
          ),
        });
      });
    }

    return accordionData;
  };

  return (
    <>
      <div className="container propertytax-container">
        <Row>
          <Col xs={{ span: 12 }}>
            <section>
              <h1>Eiendomsskatt</h1>
              {estateTaxService?.basicConfig.hideService ? (
                "Tjenesten er dessverre ikke tilgjengelig"
              ) : (
                <>
                  {estateTaxService?.basicConfig.alertText &&
                    estateTaxService?.basicConfig.alertText !== "" && (
                      <div className="infoBoxLanding">
                        <p>{estateTaxService?.basicConfig.alertText}</p>
                      </div>
                    )}
                  <p>{estateTaxService?.basicConfig.ingressText}</p>
                  <div>
                    {!isFetchingPropertyTaxItems ? (
                      propertyTaxItems ? (
                        propertyTaxItems.length >= 1 ? (
                          <AccordionView
                            hideBorder={true}
                            content={createAccordionData()}
                          />
                        ) : (
                          estateTaxService?.basicConfig.notFoundText
                        )
                      ) : (
                        "Tjenesten er dessverre ikke tilgjengelig"
                      )
                    ) : (
                      <BootstrapSpinner title="Henter din eiendomsskatt..." />
                    )}
                  </div>
                </>
              )}
            </section>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PropertyTaxPage;
