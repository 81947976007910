import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { getInboxData } from "../../Requests";

function useInboxQuery(municipalKey: string) {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useQuery({
    queryKey: ["inbox", municipalKey],
    queryFn: () => getInboxData(municipalKey, access_token),
    enabled: !!access_token,
  });
}

export { useInboxQuery };
