import {
    FETCH_CONFIGDATA_BEGIN,
    FETCH_CONFIGDATA_SUCCESS,
    FETCH_CONFIGDATA_FAILURE
} from './config.actions';
import { IMunicipalityConfig } from './municipalityConfig';

export type ConfigState = {
    config?: IMunicipalityConfig;
    loading: boolean;
    error?: string;
};

export default function configReducer(
    state: ConfigState = {
        config: undefined,
        loading: false
    },
    action: any
) {
    switch (action.type) {
        case FETCH_CONFIGDATA_BEGIN:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case FETCH_CONFIGDATA_SUCCESS:
            return {
                ...state,
                loading: false,
                config: action.payload
            };
        case FETCH_CONFIGDATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                config: undefined
            };
        default:
            return state;
    }
}