import {
    fetchOrganisationDataBegin,
    fetchOrganisationDataFailure,
    fetchOrganisationDataSuccess
  } from './organisations.actions'
  
  const endpoint = process.env.REACT_APP_DATA_API || '/404?REACT_APP_DATA_API';
  
  /**
   * Fetches organisationdata for all given organisations. 
   * @param organisationNumbers: array of strings.
   */
  export function getOrganisationData(organisationNumbers: string[], access_token: string) {
    let queryParameters = '';
    for (let i = 0; i < organisationNumbers.length; i++) {
      let seperator = i === 0 ? '?' : '&';
      queryParameters = queryParameters.concat(seperator + 'organisationNumbers=' + organisationNumbers[i])
    }
    return (dispatch: any) => {
      dispatch(fetchOrganisationDataBegin());
      return fetch(endpoint.concat('/api/organisation' + queryParameters), {
        headers: new Headers({ Authorization: 'Bearer ' + access_token })
      })
        .then(res => {
          return res.json();
        })
        .then(json => {
          dispatch(fetchOrganisationDataSuccess(json));
          return json;
        })
        .catch(error =>
          dispatch(
            fetchOrganisationDataFailure(
                new Error(error.message)
            )
          )
        );
    };
  }