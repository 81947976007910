import React from "react";
import { LinkOutIcon } from "../../Icons/LinkOutIcon";

const EstateDocuments = () => {
  return (
    <>
      <p>
        I Doctorg kan du se alle offentlige dokumenter om din eiendom fra
        arkivene byggesak, oppmåling og vann og avløp.
      </p>
      <p>
        <a
          className="ikta-button"
          href="https://doctorg.no/autologin"
          target="_blank"
          rel="noopener noreferrer"
        >
          Se dokumenter i Doctorg
          <div className="ikta-linkout">
            <LinkOutIcon />
          </div>
        </a>
      </p>
    </>
  );
};
export default EstateDocuments;
