import { State } from "../../reducers";
import { IRoleChooser, hasRole, Role } from "../roleChooser";
import { DropdownView } from "../../dropdown/dropdown.view";
import { setRole } from "../roleChooser.actions";
import StyleVariables from "../../../customStyles/custom-variables.scss";
import { useUserProfile } from "../../../Contexts/UserProfile";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BootstrapSpinner } from "../../spinners/bootstrapSpinner";

const RoleContainer = () => {
  const dispatch = useDispatch();
  const userProfile = useUserProfile();
  const organisations = useSelector(
    (state: State) => state.organisation.organisations || []
  );
  const roleLoading = useSelector((state: State) => state.role.loading);
  const roles = useSelector((state: State) => state.role.roles);
  const activeRole = useSelector((state: State) => state.role.activeRole);

  const buildRoleChooserList = (roles: IRoleChooser): any[] => {
    let data: any[] = [];
    if (hasRole(Role.PRIVATE, roles)) {
      data.push({
        rolePath: Role.PRIVATE,
        role: {
          [Role.PRIVATE]: roles[Role.PRIVATE],
          [Role.ESTATEOWNER]: roles[Role.ESTATEOWNER],
        },
        view: (
          <span>
            <svg
              className="private-person-icon"
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.51599 9.5C1.91599 9.5 0.849325 13.8333 1.01599 16H14.0156C14.1824 13.8333 13.116 9.5 7.51599 9.5Z"
                stroke={StyleVariables.mainTextColor}
              />
              <circle cx="7.51562" cy="4" r="4" />
            </svg>
            <div className="active-role-name">
              {userProfile && userProfile.name
                ? userProfile.name.toLowerCase()
                : Role.PRIVATE}
            </div>
          </span>
        ),
      });
    }
    if (hasRole(Role.ORGREP, roles)) {
      data = [
        ...data,
        ...Object.keys(roles[Role.ORGREP]).map((org: string) => ({
          rolePath: Role.ORGREP + "." + org,
          role: {
            [Role.ORGREP]: {
              [org]: hasRole(Role.ORGREP + "." + org, roles),
            },
          },
          view: (
            <span>
              <svg
                className="orgrep-icon"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1V18H12V1H1Z"
                  stroke={StyleVariables.mainTextColor}
                  strokeWidth="2"
                />
                <path
                  d="M18 18H12V5H18V18Z"
                  stroke={StyleVariables.mainTextColor}
                  strokeWidth="2"
                />
                <rect x="3" y="3.5" width="3" height="2" />
                <rect x="3" y="6.5" width="3" height="2" />
                <rect x="3" y="9.5" width="3" height="2" />
                <rect x="7" y="3.5" width="3" height="2" />
                <rect x="7" y="6.5" width="3" height="2" />
                <rect x="7" y="9.5" width="3" height="2" />
              </svg>
              <div className="active-role-name">
                {
                  (
                    organisations.find(
                      (org_info: any) => (org_info || {}).orgNr === org
                    ) || { name: org }
                  ).name
                }
              </div>
            </span>
          ),
        })),
      ];
    }
    return data;
  };

  const rolesResolved = roles || activeRole || {};
  const data = buildRoleChooserList(rolesResolved);
  if (roleLoading) {
    return (
      <div className="spinner">
        <BootstrapSpinner title="Henter roller" inline />
      </div>
    );
  } else {
    return data.length > 1 ? (
      <DropdownView
        dropDownItems={data.map((d) => d.view)}
        activeItem={
          (data.find((d) => hasRole(d.rolePath, activeRole || {})) || data[0])
            .view
        }
        onClickItem={(index: number) => {
          dispatch(setRole(data[index].role));
        }}
      />
    ) : (
      <div className="dropdown-btn">{(data[0] || {}).view}</div>
    );
  }
};

export { RoleContainer };
