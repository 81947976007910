import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { State } from "../../reducers";

const municipalName =
  process.env.REACT_APP_MUNICIPALITY_NAME || "/404?REACT_APP_MUNICIPALITY_NAME";

const simpleFooter = ["arendal"]; //Add more municipals here to enable simple footer

function Footer(): ReactElement {
  const footer = useSelector(
    (state: State) => state.config.config?.footer || undefined
  );

  return (
    <footer id="footer">
      {simpleFooter.includes(municipalName) ? (
        <>
          <div className="simple container footer-container">
            <p>
              <a
                rel="noopener"
                target="_new"
                href={footer?.privacyStatementUrl}
              >
                Personvernerklæring
              </a>
              <br />
              {footer?.availabilityStatementUrl && (
                <a
                  rel="noopener"
                  target="_new"
                  href={footer?.availabilityStatementUrl}
                >
                  Tilgjengelighetserklæring
                </a>
              )}
            </p>
          </div>
        </>
      ) : (
        <>
          <h2 className="container">Kontakt oss</h2>
          <div className="container footer-container">
            <p>
              <strong>Sentralbord</strong>
              <br />
              <a href={"tel:" + footer?.servicedeskPhone.replace(/\s/g, "")}>
                {footer?.servicedeskPhone ?? "[placeHolder]"}
              </a>
            </p>
            <p>
              <a
                rel="noopener"
                target="_new"
                href={`mailto:${footer?.mainEmail}`}
              >
                E-post til kommunen
              </a>
              {footer?.hidePostToMunicipality || (
                <>
                  <br />
                  <a
                    rel="noopener"
                    target="_new"
                    href={`https://svarut.ks.no/edialog/mottaker/${footer?.organisationNumber.replace(
                      /\s/g,
                      ""
                    )}`}
                  >
                    Send sikker digital post
                  </a>
                </>
              )}
            </p>

            <p>
              <strong>Besøksadresse</strong>
              <br />
              {footer?.visitingAddress.name ?? "[placeHolder]"}
              <br />
              {footer?.visitingAddress.address ?? "[placeHolder]"},{" "}
              {footer?.visitingAddress.zipCode ?? "[placeHolder]"}{" "}
              {footer?.visitingAddress.city ?? "[placeHolder]"}
              <br />
              <a rel="noopener" target="_new" href={footer?.openingHoursUrl}>
                Åpningstider
              </a>
            </p>
            <p>
              <strong>Postadresse</strong>
              <br />
              {footer?.postalAddress.name ?? "[placeHolder]"}
              {footer?.postalAddress.unit === "" ? "" : <br />}
              {footer?.postalAddress.unit ?? "[placeHolder]"}
              <br />
              {footer?.postalAddress.address ?? "[placeHolder]"},{" "}
              {footer?.postalAddress.zipCode ?? "[placeHolder]"}{" "}
              {footer?.postalAddress.city ?? "[placeHolder]"}
            </p>
            <p>
              <strong>Fakturaadresse</strong>
              <br />
              {footer?.invoiceAddress.name ?? "[placeHolder]"}
              <br />
              {footer?.invoiceAddress.address ?? "[placeHolder]"},{" "}
              {footer?.invoiceAddress.zipCode ?? "[placeHolder]"}{" "}
              {footer?.invoiceAddress.city ?? "[placeHolder]"}
              <br />
              {footer?.invoiceInfoUrl && (
                <a rel="noopener" target="_new" href={footer?.invoiceInfoUrl}>
                  Faktura til kommunen - EHF
                </a>
              )}
              <br />
              {footer?.invoiceSupplierInfoUrl && (
                <a
                  rel="noopener"
                  target="_new"
                  href={footer?.invoiceSupplierInfoUrl}
                >
                  For leverandører
                </a>
              )}
            </p>
            <p>
              <strong>Organisasjonsnummer</strong>
              <br />
              {footer?.organisationNumber ?? "[placeHolder]"}
              <br />
              <strong>Bankkontonummer</strong>
              <br />
              {footer?.bankaccountNumber ?? "[placeHolder]"}
              <br />
              <strong>Kommunenummer</strong>
              <br />
              {footer?.municipalityNumber ?? "[placeHolder]"}
            </p>
            <p>
              <a
                rel="noopener"
                target="_new"
                href={footer?.privacyStatementUrl}
              >
                Personvernerklæring
              </a>

              {footer?.availabilityStatementUrl && (
                <>
                  <br />
                  <a
                    rel="noopener"
                    target="_new"
                    href={footer?.availabilityStatementUrl}
                  >
                    Tilgjengelighetserklæring
                  </a>
                </>
              )}
              <br />
              <a rel="noopener" target="_new" href={footer?.cookieStatementUrl}>
                Informasjonskapsler
              </a>
              <br />
              <a href={`mailto:${footer?.contactEmail}`}>
                Kontakt webredaksjonen
              </a>
            </p>
          </div>
        </>
      )}
    </footer>
  );
}
export default Footer;
