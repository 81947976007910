import { createStore, compose ,applyMiddleware } from 'redux';
import thunkMiddleWare from 'redux-thunk';
import rootReducer from './reducers/index';

export default function configureStore() {
    const middlewares = [thunkMiddleWare]
    const middlewareEnhancers = applyMiddleware(...middlewares)

    //Only apply redux dev tools if the extension for the client exists.
    const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    const enhancers = [middlewareEnhancers, reduxDevTools]
    const composedEnhancers = compose(...enhancers)


    //no preloadedState. 
    const store = createStore(rootReducer, {}, composedEnhancers)

    return store
}