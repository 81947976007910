import { Nav } from 'react-bootstrap';
import React from 'react';
/**
 * @param onClick: function for handling onClick functionality. (Parent tab must handle  tab active state)
 * @param key: Unique ID (amongst others TabHeaders in same collection)
 * @param title: TabHeader title
 */
export default function TabHeader(props: any) {
  return (
    <Nav.Item onClick={props.onClick} className={props.className}>
      <Nav.Link>{props.title}</Nav.Link>
    </Nav.Item>
  );
}
