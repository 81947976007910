import React from "react";
import { Table } from "react-bootstrap";
import { IEstate, Owner } from "../../estates/estateRelation";

type EstateCoreInformationProps = {
  estate: IEstate;
  address?: string | undefined;
};
const EstateCoreInformation = (props: EstateCoreInformationProps) => {
  const getOwnerRelation = (ownerRelationCode: string) => {
    switch (ownerRelationCode.toUpperCase()) {
      case "H":
        return "(Hjemmelshaver)";
      case "F":
        return "(Fester)";
      default:
        return "";
    }
  };
  return (
    <>
      <Table striped hover size="sm" responsive borderless>
        <thead className="tableHeader">
          <tr>
            <th>Beskrivelse</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Adresse</td>
            <td>{props.address}</td>
          </tr>
          <tr>
            <td>Kommune</td>
            <td>
              {`${
                props.estate.address
                  ? props.estate.address.municipalityName
                  : ""
              } ${
                props.estate.address
                  ? props.estate.address.municipalityNumber
                  : ""
              }`}
            </td>
          </tr>
          <tr>
            <td>Gårdsnummer</td>
            <td>{props.estate.ownerRelation.matrikkelenhetIdent.gardsnr}</td>
          </tr>
          <tr>
            <td>Bruksnummer</td>
            <td>{props.estate.ownerRelation.matrikkelenhetIdent.bruksnr}</td>
          </tr>
          {props.estate.ownerRelation.owners != null &&
            props.estate.ownerRelation.owners.length >= 1 && (
              <tr>
                <td>Eies av</td>
                <td>
                  <ul style={{ paddingLeft: 0 }}>
                    {props.estate.ownerRelation.owners
                      .sort((a: Owner, b: Owner) => {
                        if (a.ownerRelationCode < b.ownerRelationCode)
                          return -1;

                        return 1;
                      })
                      .map((owner: Owner, index: number) => (
                        <li key={index} className="owner-name">
                          {owner.name.toLowerCase() +
                            " " +
                            getOwnerRelation(owner.ownerRelationCode)}
                        </li>
                      ))}
                  </ul>
                </td>
              </tr>
            )}

          <tr>
            <td>Eiendommens areal</td>
            <td>
              {props.estate.matrikkelenhet.calculatedArea &&
                `${props.estate.matrikkelenhet.calculatedArea.toFixed(1)} m²`}
            </td>
          </tr>
          <tr>
            <td>Eierbrøk</td>
            <td>
              {props.estate.matrikkelenhet.coOwnershipFraction.numerator &&
                props.estate.matrikkelenhet.coOwnershipFraction.denominator &&
                `${props.estate.matrikkelenhet.coOwnershipFraction.numerator} / ${props.estate.matrikkelenhet.coOwnershipFraction.denominator}`}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default EstateCoreInformation;
