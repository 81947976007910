import { useSelector } from "react-redux";
import { State } from "../../common/reducers";
import { useQuery } from "@tanstack/react-query";
import { getPropertyTaxData } from "../../Requests";

function usePropertyTaxQuery(municipalKey: string, ownerId: string) {
  const access_token = useSelector(
    (state: State) => (state.auth.user && state.auth.user.access_token) || ""
  );
  return useQuery({
    queryKey: ["propertytax", municipalKey],
    queryFn: () => getPropertyTaxData(municipalKey, access_token, ownerId),
    enabled: !!access_token,
  });
}

export { usePropertyTaxQuery };
