import {
    fetchConfigDataBegin,
    fetchConfigDataSuccess,
    fetchConfigDataFailure
} from './config.actions';

const endpoint = process.env.REACT_APP_DATA_API || '/404?REACT_APP_DATA_API';
const municipalName = process.env.REACT_APP_MUNICIPALITY_NAME || '/404?REACT_APP_MUNICIPALITY_NAME';

/**
 * Fetches config for given municipal.
 * @param access_token string, access_token from ID porten.
 */
export function getMunicipalConfigData(access_token: string) {
    return (dispatch: any) => {
        dispatch(fetchConfigDataBegin());
        return fetch(endpoint.concat('/api/config?domain=minside&key=' + municipalName.toLowerCase()), {
            headers: new Headers({Authorization: 'Bearer ' + access_token})
        })
        .then(res => {
            return res.json();
        })
        .then(json => {
            dispatch(fetchConfigDataSuccess(json));
            return json;
        })
        .catch(error => 
            dispatch(
                fetchConfigDataFailure(
                    new Error(error.message)
                )
            )
        );
    };
}

