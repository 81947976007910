import { UserManager, User } from "oidc-client";

const manager = new UserManager({
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT,
  redirect_uri: window.location.origin + "/login/response",
  post_logout_redirect_uri: window.location.origin + "/logout/response",
  response_type: "code",
  scope: "openid profile krr:user/kontaktinformasjon.read ks:fiks",
  acr_values: "idporten-loa-substantial",
  ui_locales: "nb",
  loadUserInfo: false,
  revokeAccessTokenOnSignout: false,
  extraQueryParams: {
    resource: process.env.REACT_APP_OIDC_RESOURCE,
    onbehalfof: process.env.REACT_APP_MUNICIPALITY_NUMBER,
  },
});

export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

const loginStart = () => ({
  type: LOGIN_BEGIN,
});

const loginSuccess = (user: User) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

const loginFailed = (error: Error) => ({
  type: LOGIN_FAILED,
  payload: error,
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

const logoutFailed = (error: Error) => ({
  type: LOGOUT_FAILED,
  payload: error,
});

export const login = () => (dispatch: Function) => {
  dispatch(loginStart());
  manager.getUser().then((user: User | null) => {
    if (!!user && !user.expired) {
      dispatch(loginSuccess(user));
    } else {
      manager
        .signinRedirect()
        .catch((error: Error) => dispatch(loginFailed(error)));
    }
  });
  // Setup autorefresh
  manager.events.addAccessTokenExpiring(() => {
    manager.signinSilent().then(() => {
      manager.getUser().then((user: User | null) => {
        if (!!user && !user.expired) {
          dispatch(loginSuccess(user));
        }
      });
    });
  });
};

export const completeLogin = () => (dispatch: Function) => {
  manager
    .signinRedirectCallback()
    .then(() => {
      manager.getUser().then((value: User | null) => {
        if (value === null) throw new Error("Failed to get user");
        return dispatch(loginSuccess(value));
      });
    })
    .catch((error: Error) => {
      dispatch(loginFailed(error));
    });
};

export const logout = () => (dispatch: Function) => {
  manager
    .signoutRedirect()
    .catch((error: Error) => dispatch(logoutFailed(error)));
};

export const completeLogout = () => (dispatch: Function) => {
  manager
    .signoutRedirectCallback()
    .then(manager.removeUser)
    .then(() => {
      return dispatch(logoutSuccess());
    })
    .catch((error) => dispatch(logoutFailed(error)));
};
