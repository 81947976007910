import { Accordion } from "react-bootstrap";
import React, { useState } from "react";
import AccordionCardContentView from "./accordionCardContent.view";
import { IAccordionData } from "../interfaces/accordionData";

export type AccordionProps = {
  content: IAccordionData[];
  reference?: any;
  showNarrow?: boolean;
  hideBorder?: boolean;
  mapref?: any;
};

export function AccordionView(props: AccordionProps) {
  const [key, setKey] = useState("");

  if (props.reference) {
    props.reference.reset = () => {
      setKey("");
    };
  }

  function createContent(content: IAccordionData[]) {
    return content.map((c: IAccordionData, index: any) => {
      index = index.toString();
      return (
        <AccordionCardContentView
          key={index}
          onClick={() => {
            setKey(key === index ? "" : index);
            if (props.mapref) window.dispatchEvent(new Event("resize"));
          }}
          onEntered={c.onClick}
          title={c.title}
          isActive={
            key === index ||
            (props.content.length === 1 && props.showNarrow !== true)
          }
          eventKey={index}
          content={c.content}
          showNarrow={props.showNarrow}
        />
      );
    });
  }

  return (
    <Accordion
      className={
        "accordionContainer " +
        (props.hideBorder === true ? "accorion-no-border" : "accorion")
      }
      activeKey={key}
    >
      {createContent(props.content)}
    </Accordion>
  );
}
