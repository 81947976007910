import React, { useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import isAuthenticated from "../isAuthenticated";
import { useSelector } from "react-redux";
import { State } from "../../reducers";

function PrivateRoute() {
  const user = useSelector((state: State) => state.auth.user);
  let location = useLocation();

  useEffect(() => {
    sessionStorage.setItem("location", location.pathname)
  }, )

  return <>{isAuthenticated(user) ? <Outlet /> : <Navigate to={"/login"} />}</>;
}

export default PrivateRoute;
